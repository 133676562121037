import React from "react";
import * as Components from "../../../../Components";

const TourDetail = () => {
	// Site Title...
	document.title = "Tour Detail || Travel Craze";

	return (
		<>
			{/* Top Banner */}
			<Components.TopBanner text="Tour Detail" />

			{/* Tour Detail Main Content */}
			<Components.TourDetailMainContent />
		</>
	);
};

export default TourDetail;
