import React from "react";
import * as Components from "../../../Components";

const Cars = () => {
	// Site Title...
	document.title = "Cars || Travel Craze";
	return (
		<>
			{/* Top Banner */}
			<Components.TopBanner text="Cars" />

			{/* Car Main Content */}
			<Components.CarMainContent />
		</>
	);
};

export default Cars;
