import React from "react";
import * as Components from "../../../../Components";

const CarDetail = () => {
	// Site Title...
	document.title = "Car Detail || Travel Craze";

	return (
		<>
			{/* Top Banner */}
			<Components.TopBanner text="Car Detail" />

			{/* Car Detail Main Content */}
			<Components.CarDetailMainContent />
		</>
	);
};

export default CarDetail;
