import { useState } from "react";
import CustomeTextFieldTranslate from "./CustomeTextFieldTranslate";

const Translate = ({data,setState}) => {
    
    const updatedLanguages = (code,objKey,value) => {
        // if(data.hasOwnProperty(code) && value == ''){
        //     const updatedLanguages = Object.keys(data).reduce((acc, key) => {
        //         if (key !== code) {
        //           acc[key] = data[key];
        //         }
        //         return acc;
        //       }, {});
        //       setState(updatedLanguages);
        //     return;
        // }
        setState(prevLanguage => ({ ...prevLanguage, [code]: {...prevLanguage[code],[objKey]:value} }))
    }
    return (
        <>
            {
                Object.keys(data).map((key)=>{
                    const item = data[key];
                    return (
                        <div className="mb-4">
                            <p>{item.lang_title}</p>

                            <CustomeTextFieldTranslate value={item.name} code={key} objKey="name" changeState={updatedLanguages} placeholder="Hotel Name" />

                            <CustomeTextFieldTranslate value={item.description} code={key} objKey="description" changeState={updatedLanguages} placeholder="Hotel Description" />

                            <CustomeTextFieldTranslate value={item.meta_title} code={key} objKey="meta_title" changeState={updatedLanguages} placeholder="Meta Title" />

                            <CustomeTextFieldTranslate value={item.meta_keywords} code={key} objKey="meta_keywords" changeState={updatedLanguages} placeholder="Meta Keywords" />

                            <CustomeTextFieldTranslate value={item.meta_description} code={key} objKey="meta_description" changeState={updatedLanguages} placeholder="Meta Description" />

                            <CustomeTextFieldTranslate value={item.policy_terms} code={key} objKey="policy_terms" changeState={updatedLanguages} placeholder="Policy and Terms" />
                        </div>
                    );
                })
            }

        </>
    );
}

export default Translate;