import React from "react";
import * as Hooks from "../../../Hooks";
import { MdLocationOn } from "react-icons/md";
import { AiFillStar } from "react-icons/ai";

const FeaturedTourCard = (props) => {
	return (
		<>
		{props.featured_tours && props.featured_tours.length >= 1 && (
			props.featured_tours.map((item, index) => (
				<div className="item" key={index}>
				<div className="box_grid">
					<figure>
						<Hooks.Link
							to="/"
							className="wish_bt"
						/>
						<Hooks.Link to="/">
							<img
								src={item.image}
								className="img-fluid"
								alt="Card"
								width={800}
								height={533}
							/>
							<div className="read_more">
								<span>Read more</span>
							</div>
						</Hooks.Link>
						{item.tag === true && <small>POPULAR</small>}

						{item.discount > 0 ? item.tag === true ? <small className="mt-4">{item.discount + "% DISCOUNT"}</small> : <small>{item.discount + "% DISCOUNT"}</small> : ""}
					</figure>
					<div className="wrapper">
						{item.rating > 0 && (
							<div className="cat_star mb-1">
								<AiFillStar className="featured_card_star_icon yellow" />
								<AiFillStar className="featured_card_star_icon gray" />
								<AiFillStar className="featured_card_star_icon gray" />
								<AiFillStar className="featured_card_star_icon gray" />
								<AiFillStar className="featured_card_star_icon gray" />
							</div>
						)}

						<h3>
							<Hooks.Link to="/">{item.title}</Hooks.Link>
						</h3>
						<span className="price">
							<strong>{item.currency_symbol + " " + item.price}</strong> 1 Day / 1 Night
						</span>
						<p className="mt-2">
							<MdLocationOn className="featured_card_location_icon" />
							{item.location}
						</p>
					</div>

					{/* Book Now Button */}
					<div className="book_now_button_wrapper">
						<Hooks.Link
							to="/"
							className="btn_1 full-width rounded">
							Book Now
						</Hooks.Link>
					</div>
				</div>
			</div>
			))
		)}
		</>
	);
};

export default FeaturedTourCard;
