import React from "react";
import OwlCarousel from "react-owl-carousel";
import * as Hooks from "../../../Hooks";
import * as ReusebleComponents from "../../../ReuseableComponents";

const FraturedCars = (props) => {
	// Owl Carousel Options...
	const Options = {
		center: true,
		loop: true,
		nav: false,
		dots: false,
		autoplay: true,
		autoplayTimeout: 3000,
		autoplayHoverPause: true,
		responsiveClass: true,
		responsive: {
			0: {
				items: 1,
			},
			600: {
				items: 3,
			},
			1000: {
				items: 3,
			},
		},
	};
	return (
		<>
			<div className="container-fluid container-custom margin_80_0">
				<div className="main_title_2">
					<span>
						<em></em>
					</span>
					<h2>Featured Cars</h2>
				</div>

				{/* Card */}
				<OwlCarousel
					className="owl-theme"
					id="reccomended"
					{...Options}>
					<ReusebleComponents.FeaturedCarCard
						featured_cars={props.featured_cars}
					/>
				</OwlCarousel>

				<p className="btn_home_align">
					<Hooks.Link
						to="/cars"
						className="btn_1 rounded">
						View All Cars
					</Hooks.Link>
				</p>
				<hr className="large" />
			</div>
		</>
	);
};

export default FraturedCars;
