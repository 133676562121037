import React from "react";
import * as Components from "../../../../Components";

const HotelDetail = () => {
	// Site Title...
	document.title = "Hotel Detail || Travel Craze";

	return (
		<>
			{/* Top Banner */}
			<Components.TopBanner text="Hotel Detail" />

			{/* Hotel Detail Main Content */}
			<Components.HotelDetailMainContent />
		</>
	);
};

export default HotelDetail;
