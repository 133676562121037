import React from "react";

const Map = () => {
	return (
		<>
			<iframe
				src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7017.54683846147!2d70.30558!3d28.426093!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39375b72b873ecfb%3A0xd2862522f2fda403!2sXpertSpot%20System!5e0!3m2!1sen!2sus!4v1690434439686!5m2!1sen!2sus"
				style={{ border: 0 }}
				allowFullScreen=""
				loading="lazy"
				title="Hotels Location"
				className="map map_single add_bottom_30"
				referrerPolicy="no-referrer-when-downgrade"></iframe>
		</>
	);
};

export default Map;
