import { useEffect, useState } from "react";
import { Select, FormControl, TextField, MenuItem, InputLabel, Grid, } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ADMIN_APIS_HOTEL } from "../../../Constants";
import ReuseableSelect from "./reusableSelect";
import CustomeTextField from "./CustomeTextField";
import dayjs from 'dayjs';

const SideForm = ({ sideformData, setSideformData, hotels, roomTypes }) => {

    const setHotelMenuItems = () => {
        return hotels && hotels.map((item) => {
            return <MenuItem value={item.id}>{item.name}</MenuItem>
        })
    }

    const setRoomTypeMenuItems = () => {
        return roomTypes && roomTypes.map((item) => {
            return <MenuItem value={item.id}>{item.name}</MenuItem>
        })
    }

    const setStatusMenuItems = () => {
        let status = [{ 'value': 'Yes', 'label': 'Enable' }, { 'value': 'No', 'label': 'Disable' }];
        return status.map((item) => {
            return <MenuItem value={item.value}>{item.label}</MenuItem>
        })
    }

    const changeSideform = (key, value) => {
        setSideformData({ ...sideformData, [key]: value });
    }

    console.log('65 ;;',sideformData);

    return (
        <>
            <div>
                <ReuseableSelect label="Status" value={sideformData.status} objKey='status' changeState={changeSideform} data={setStatusMenuItems} />

                <CustomeTextField value={sideformData.price} type='number' objKey='price' changeState={changeSideform} placeholder="Price" />

                <ReuseableSelect label="Room Type" value={sideformData.room_type} objKey='room_type' changeState={changeSideform} data={setRoomTypeMenuItems} />

                <ReuseableSelect label="Hotel" value={sideformData.hotel} objKey='hotel' changeState={changeSideform} data={setHotelMenuItems} />

                <CustomeTextField value={sideformData.quantity} type='number' objKey='quantity' changeState={changeSideform} placeholder="Quantity" />

                <CustomeTextField value={sideformData.min_stay} type='number' objKey='min_stay' changeState={changeSideform} placeholder="Min Stay" />

                <CustomeTextField value={sideformData.max_adults} type='number' objKey='max_adults' changeState={changeSideform} placeholder="Max Adults" />

                <CustomeTextField value={sideformData.max_child} type='number' objKey='max_child' changeState={changeSideform} placeholder="Max Child" />

                <CustomeTextField value={sideformData.extra_beds} type='number' objKey='extra_beds' changeState={changeSideform} placeholder="No. of Extra Beds" />

                <CustomeTextField value={sideformData.extra_beds_charges} type='number' objKey='extra_beds_charges' changeState={changeSideform} placeholder="Extra Beds Charges" />

            </div>
        </>
    );
}

export default SideForm;