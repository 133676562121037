import React from "react";
import * as ReuseableComponents from "../../../ReuseableComponents";
// import * as Hooks from "../../../Hooks";

const TourDescriptionContent = () => {
	return (
		<>
			{/* Image */}
			<img
				src="/backend/img/additional/tours/tour_1.jpg"
				alt="Tour Image"
				className="detail_image"
			/>

			{/* Title */}
			<h2 className="font_weight_bold mb-0">New York Tower</h2>

			{/* Rating */}
			<div className="cat_star mb-2">
				<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon yellow" />
				<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
				<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
				<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
				<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
			</div>

			{/* Price */}
			<span className="price_detail mb-1">
				<strong>PKR 1,000</strong> Per Night
			</span>

			{/* Location */}
			<p>
				<ReuseableComponents.ReactIcons.LOCATION_ON className="featured_card_location_icon me-1" />
				London
			</p>

			{/* Over View */}
			<div className="main_title_3 mt-3">
				<span>
					<em></em>
				</span>
				<h2>Over View</h2>
			</div>
			<p className="text_justify">
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
				viverra rutrum aliquam. Sed posuere quam eget tellus dictum, et
				suscipit tellus pellentesque. Nullam elementum dui sit amet
				feugiat placerat. In aliquam dignissim convallis. Sed vestibulum
				erat turpis, nec finibus nisl ullamcorper semper. Maecenas ante
				odio, fermentum at nunc sit amet, posuere consectetur nibh.
				Donec vel nibh posuere, semper augue at, eleifend quam. Nulla at
				consequat ante. Praesent fermentum leo sed scelerisque tempus.
				Duis arcu nunc, accumsan at hendrerit sed, euismod nec sem.
				Donec vel pellentesque dolor. Mauris finibus pellentesque
				lobortis. Aenean semper nisi non congue cursus. Pellentesque
				fringilla quam sodales, mattis lorem quis, elementum nulla.
				Integer at facilisis libero. Aenean venenatis ac lectus eu
				bibendum. Praesent enim urna, consequat ac ultricies at, semper
				et quam. Vestibulum non urna tristique, posuere leo in, gravida
				purus. Phasellus pretium ac justo vel interdum. Vestibulum ante
				ipsum primis in faucibus orci luctus et ultrices.
			</p>

			{/* Tours Package Type */}
			<div className="main_title_3 mt-3">
				<span>
					<em></em>
				</span>
				<h2>Tours Package Type</h2>
			</div>
			<ReuseableComponents.TourDetailsPackageType />

			{/* Find Us Here */}
			<div className="main_title_3 mt-3">
				<span>
					<em></em>
				</span>
				<h2>Find Us Here</h2>
			</div>
			<ReuseableComponents.Map />

			{/* Privacy Policy */}
			<div className="main_title_3 mt-3">
				<span>
					<em></em>
				</span>
				<h2>Privacy Policy</h2>
			</div>
			<ReuseableComponents.TourDetailsPrivacyPoicy />
		</>
	);
};

export default TourDescriptionContent;
