import React from "react";
import * as Hooks from "../../../Hooks";

const FooterBottom = () => {
	return (
		<>
		
			<div className="row">
				<div className="col-12">
					<div className="text-center">
						<p>
							© 2023 Travel Craze. Represented By{" "}
							<Hooks.Link
								to="https://xpertsgroup.net/"
								className="footer_website_link">
								XpertSpot Systems
							</Hooks.Link>
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default FooterBottom;
