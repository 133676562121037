import React from "react";
import * as ReuseableComponents from "../../../ReuseableComponents";
import { Button } from "react-bootstrap";

const ContactUsMainContent = () => {
	return (
		<>
			<div className="bg_color_1">
				<div className="container margin_80_55">
					<div className="row justify-content-between">
						<div className="offset-md-2 col-md-7">
							<h4>
								Please fill out the form below and we will get
								back to you as soon as possible. Need quicker
								answers? Call us at given numbers.
							</h4>

							<div className="row">
								{/* Name */}
								<div className="col-md-6">
									<div className="form-group">
										<label>Name</label>
										<input
											className="form-control"
											type="text"
											id="name"
											name="name"
										/>
									</div>
								</div>

								{/* Email Address */}
								<div className="col-md-6">
									<div className="form-group">
										<label>Email Address</label>
										<input
											className="form-control"
											type="email"
											id="email_Address"
											name="email_Address"
										/>
									</div>
								</div>
							</div>

							<div className="row">
								{/* Subject */}
								<div className="col-12">
									<div className="form-group">
										<label>Email</label>
										<input
											className="form-control"
											type="email"
											id="email_contact"
											name="email_contact"
										/>
									</div>
								</div>
							</div>

							{/* Message */}
							<div className="row">
								<div className="col-12">
									<div className="form-group">
										<label>Message</label>
										<textarea
											className="form-control"
											id="message"
											name="message"
											style={{ height: 150 }}
										/>
									</div>
								</div>
							</div>

							{/* Button */}
							<Button className="btn_1 rounded float-end mt-2">
								Submit
							</Button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ContactUsMainContent;
