import React from "react";
import { Button, Modal } from "react-bootstrap";
import * as ReuseableComponents from "../../../ReuseableComponents";
import * as Hooks from "../../../Hooks";

const TourBookingFormModal = (props) => {
	// Navigate...
	const navigate = Hooks.useNavigate();

	// On Handle Submit...
	const onHandleSubmit = () => {
		navigate("/tours/invoice");
	};

	return (
		<>
			<Modal
				className="fade zoom tour_package_booking_form_modal"
				show={() => props.modalOpen(true)}
				onHide={() => props.modalClose(false)}
				backdrop="static"
				size="lg"
				centered>
				{/* Header */}
				<Modal.Header className="border-0 px-4 modal_header">
					<Modal.Title>Tours Booking</Modal.Title>
					<ReuseableComponents.ReactIcons.OUTLINE_CLOSE
						className="modal_header_close_button"
						onClick={() => props.modalClose(false)}
					/>
				</Modal.Header>

				{/* Body */}
				<Modal.Body className="pb-0 text-start">
					<div className="row">
						<div className="col-sm-12 col-md-4">
							{/* Image */}
							<img
								src="/backend/img/additional/tours/tour_1.jpg"
								alt="Tour Image"
								className="form_image"
							/>
						</div>

						<div className="col-sm-12 offset-md-1 col-md-7 mt-3 mt-md-0">
							{/* Title */}
							<h3 className="font_weight_bold mb-0">
								New York Tower
							</h3>

							{/* Rating */}
							<div className="cat_star mb-2">
								<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon yellow" />
								<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
								<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
								<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
								<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
							</div>

							{/* Price */}
							<span className="price_detail mb-1">
								<strong>PKR 1,000</strong> Per Night
							</span>
						</div>
					</div>
					<hr className="my-3" />

					<div className="row">
						{/* Name */}
						<div className="col-sm-12 col-md-6">
							<div className="form-group">
								<input
									type="text"
									id="name"
									name="name"
									className="form-control"
									placeholder="Name"
								/>
							</div>
						</div>

						{/* Email Address */}
						<div className="col-sm-12 col-md-6 mt-3 mt-md-0">
							<div className="form-group">
								<input
									type="text"
									id="email_address"
									name="email_address"
									className="form-control"
									placeholder="Email Address"
								/>
							</div>
						</div>
					</div>

					<div className="row mt-3 mt-md-0">
						{/* Contact No */}
						<div className="col-sm-12 col-md-6">
							<div className="form-group">
								<input
									type="number"
									id="contact_no"
									name="contact_no"
									className="form-control"
									placeholder="Contact No"
								/>
							</div>
						</div>

						{/* Address */}
						<div className="col-sm-12 col-md-6 mt-3 mt-md-0">
							<div className="form-group">
								<input
									type="text"
									id="address"
									name="address"
									className="form-control"
									placeholder="Address"
								/>
							</div>
						</div>
					</div>
				</Modal.Body>

				{/* Footer */}
				<Modal.Footer className="d-flex border-0 pb-2 pt-0">
					<Button
						className="btn_1 cancel rounded"
						onClick={() => props.modalClose(false)}>
						Cancel
					</Button>
					<Button
						className="btn_2 rounded"
						onClick={onHandleSubmit}>
						Book Now
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default TourBookingFormModal;
