const Dashboard = () => {
    return (
        <>
            <div>
                <h2>Dashboard</h2>
            </div>
        </>
    );
}

export default Dashboard;