import React from "react";
import { ToastContainer, toast } from "react-toastify";
import * as Hooks from "../../../Hooks";
import * as ReuseableComponents from "../../../ReuseableComponents";

const HotelInvoiceMainContent = () => {
	// Show Payment Method Toast...
	const showPaymentMethodToast = () => {
		toast.error("Your booking status is unpaid!");
	};

	// Use Effect...
	Hooks.useEffect(() => {
		showPaymentMethodToast();
	}, []);
	return (
		<>
			<div className="bg_color_1">
				{/* Toast */}
				<ToastContainer position="bottom-left" />

				<div className="container padding_30_30">
					<div className="row">
						{/* Main Content */}
						<div className="col-lg-8">
							{/* Booking Details */}
							<div className="box_detail">
								<div id="total_cart">Booking Details</div>
								<ul className="cart_details border-0 mb-0">
									<li className="mb_12">
										Invoice Number <span>#0000</span>
									</li>
									<li className="mb_12">
										Booking Number <span>#0000</span>
									</li>
									<li className="mb_12">
										Full Name <span>John Doe</span>
									</li>
									<li className="mb_12">
										Contact No <span>+92 300 1234 567</span>
									</li>
									<li className="mb_12">
										Address <span>New York</span>
									</li>
								</ul>

								<h6 className="font_weight_bold">
									Notes / Additional Requests
								</h6>
								<div className="form-group">
									<textarea
										className="form-control text_arae"
										disabled
										placeholder="Your Review"></textarea>
								</div>
							</div>

							{/* Hotel Details */}
							<div className="box_detail">
								<div id="total_cart">Hotel Details</div>
								<ul className="cart_details border-0 mb-0">
									<li className="mb_12">
										Name
										<span>Grand Plaza Apartments</span>
									</li>
									<li className="mb_12">
										Contact No <span>+92 300 7890 123</span>
									</li>
									<li className="mb_12">
										Location <span>Alexandria</span>
									</li>
								</ul>
							</div>
						</div>

						<aside
							className="col-lg-4"
							id="sidebar">
							<div className="box_detail mb-0">
								<div className="row">
									{/* Image */}
									<div className="col-sm-12 col-md-5">
										<img
											src="/backend/img/additional/hotels/hotel_1.jpg"
											alt="Hotel Image"
											className="invoice_image"
										/>
									</div>

									{/* Content */}
									<div className="col-sm-12 col-md-7 mt-3 mt-md-0">
										{/*Title  */}
										<h3 className="font_weight_bold my-0 fs_15">
											Grand Plaza Apartments
										</h3>

										{/* Rating */}
										<div className="cat_star">
											<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon yellow" />
											<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
											<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
											<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
											<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
										</div>

										{/* Location */}
										<p className="mb-0">
											<ReuseableComponents.ReactIcons.LOCATION_ON className="featured_card_location_icon me-1" />
											London
										</p>
									</div>
								</div>
								<hr className="my-3" />

								{/* Summary */}
								<div id="total_cart">Summary</div>
								<ul className="cart_details border-0">
									<li>
										Date <span>August 01, 2023</span>
									</li>
									<li>
										Check In<span>August 01, 2023</span>
									</li>
									<li>
										Check Out <span>August 02, 2023</span>
									</li>
									<li>
										Total Stay
										<span>02</span>
									</li>
									<li>
										Extra Bed <span>00</span>
									</li>
								</ul>

								{/* Charges */}
								<div id="total_cart">Charges</div>
								<ul className="cart_details mb-0">
									<li>
										Tax / VAT<span>PKR 00</span>
									</li>
								</ul>

								<h6 className="mt-3">
									Total Amount{" "}
									<span className="float-end">PKR 00</span>
								</h6>
							</div>
						</aside>
					</div>

					{/* Info */}
					<div className="row mt-5">
						<div className="col-sm-12 col-md-8">
							<h3 className="font_weight_bold mb-2">
								Why book with us?
							</h3>
							<p className="mb-1">
								<ReuseableComponents.ReactIcons.CHECK_SQUARE className="check_square_icon" />{" "}
								100% Secured Payments
							</p>
							<p className="mb-1">
								<ReuseableComponents.ReactIcons.CHECK_SQUARE className="check_square_icon" />{" "}
								Book online or call us anyone
							</p>
							<p className="mb-1">
								<ReuseableComponents.ReactIcons.CHECK_SQUARE className="check_square_icon" />{" "}
								We aim to provide the lowest rates possible
							</p>
						</div>

						<div className="col-sm-12 col-md-4 mt-3 mt-md-0 text-end">
							<h3 className="font_weight_bold mb-2">
								Travel Craze
							</h3>
							<p className="mb-1">Rahim Yar Khan</p>
							<p className="mb-1">info@xpertspot.com</p>
							<p className="mb-1">+1-234-56789</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default HotelInvoiceMainContent;
