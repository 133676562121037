import "../assets/css/dashlite.css";
import "../assets/css/additional_styling.css";
import "../assets/css/theme.css";
import "react-pro-sidebar/dist/css/styles.css";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import './custome.scss'
import * as Hooks from "../../../Hooks";
import { AiOutlineSetting } from "react-icons/ai";
import {
  BsFillCartCheckFill,
  BsArrowDownRightSquare,
  BsBicycle,
} from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { MdDashboardCustomize, MdManageAccounts } from "react-icons/md";
import { FcSalesPerformance } from "react-icons/fc";
// import { BiPurchaseTagAlt } from "react-icons/bi";
import { CgShoppingBag } from "react-icons/cg";
import { RiFileUserLine } from "react-icons/ri";
import { HiOutlineDocumentText } from "react-icons/hi";

const DashboardSidebar = () => {
    return (
        <>
      <ProSidebar className="SidebarBg">
        <Hooks.Link to="/dashboard">
          {/* <h3
                style={{
                  color: "#fff",
                  padding: "20px 35px 8px 20px",
                  borderBottom: "1px solid #e5e9f2",
                }}
              >
                ALBREDY
              </h3> */}
          <img 
          // src={image} 
          alt="" className="m-3 mx-auto d-block" />
        </Hooks.Link>

        {/* Dashboard */}
        <Menu iconShape="circle">
          <MenuItem icon={<MdDashboardCustomize />}>
            Dashboard
            <Hooks.Link to="/dashboard" />
          </MenuItem>
          {/* Settings */}
          <Menu iconShape="circle">
            <SubMenu title="Hotel" icon={<AiOutlineSetting />}>
              <MenuItem>
                Hotels
                <Hooks.Link to="/dashboard/hotels" />
              </MenuItem>
              <MenuItem>
                Rooms
                <Hooks.Link to="/dashboard/rooms" />
              </MenuItem>
              <MenuItem>
                Settings
                <Hooks.Link to="/dashboard/hotels/settings" />
              </MenuItem>
            </SubMenu>
          </Menu>
          <Menu iconShape="circle">
            <MenuItem icon={<CgShoppingBag />}>
              Locations
              <Hooks.Link to="/dashboard/locations" />
            </MenuItem>
          </Menu>
          {/* Orders */}
          {/* <Menu iconShape="circle" >
                    <SubMenu title="Orders" icon={<CgShoppingBag/>}>
                        <MenuItem>List Orders<Hooks.Link to="/list-orders" /></MenuItem>
                        <MenuItem>Add Orders<Hooks.Link to="/add-orders" /></MenuItem>
                    </SubMenu>
                </Menu> */}
          <Menu iconShape="circle">
            <MenuItem icon={<CgShoppingBag />}>
              Orders
              <Hooks.Link to="/list-orders" />
            </MenuItem>
          </Menu>

          {/* Rider */}
          {/* <MenuItem icon={<MdManageAccounts />}>
            Rider
            <Hooks.Link to="/list-rider" />
          </MenuItem> */}
        </Menu>
      </ProSidebar>
    </>
    );
}

export default DashboardSidebar;