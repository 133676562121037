import React from "react";
import "../../Styles";
import "../../Scripts";
import * as Hooks from "../../Hooks";
import * as Components from "../../Components";

const DashboardLayout = () => {
	// States...
	const [window_scroll, setWindowScroll] = Hooks.useState(false);

	// UseEffect...
	Hooks.useEffect(() => {
		window.addEventListener("scroll", () => {
			setWindowScroll(window.scrollY > 50);
		});
	}, []);
	return (
		<div>
			<div className="nk-app-root">
				{/* main @s */}
				<div className="nk-main ">
					{/* sidebar @s */}
					<div
						className="nk-sidebar nk-sidebar-fixed is-light"
						data-content="sidebarMenu"
					>
						<Components.DashboardSidebar />
					</div>
					{/* Sidebar @e */}
					{/* wrap @s */}
					<div className="nk-wrap " style={{ background: "#eee", paddingTop:'75px' }}>
						{/* main header @s */}
						<div className="nk-header nk-header-fixed is-light">
							{/* Header */}
							<Components.DashboardHeader />
						</div>
						{/* main header @e */}

						{/* content @s */}
						{/* {!open ? ( */}
							<main className="p-3">
								<div style={{ backgroundColor: "white", padding: "20px", borderRadius: "15px",boxShadow: "0 0 50px #ccc" }}>
									<Hooks.Outlet />
								</div>
							</main>
						{/*  ) : (
						 	<DashBoardSkeleton />
						 )} */}
						{/* content @e */}
					</div>
					{/* wrap @e */}
				</div>
				{/* main @e */}
			</div>
			{/* nk-app-root */}
		</div>
	);
};

export default DashboardLayout;
