import React from "react";
import * as Components from "../../../Components";
import CONSTANTS from "../../../Data";

const Home = () => {
	// Site Title...
	document.title = "Home || Travel Craze";

	return (
		<>
			{/* Home Banner */}
			<Components.HomeBanner />

			{/* Featured Hotels */}
			<Components.FraturedHotels
				featured_hotels={CONSTANTS.FEATURED_HOTELS.featured_hotels}
			/>

			{/* Featured Tours */}
			<Components.FraturedTours
				featured_tours={CONSTANTS.FEATURED_TOURS.featured_tours}
			/>

			{/* Featured Cars */}
			<Components.FraturedCars
				featured_cars={CONSTANTS.FEATURED_CARS.featured_cars}
			/>
		</>
	);
};

export default Home;
