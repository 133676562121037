import { Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
const Policy = ({policyData,setPolicyData}) => {

    const changePolicyData = (key,value) => {
        setPolicyData({...policyData,[key]:value});
    }
    return (
        <>
            <Grid container>
                <Grid item md={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker label="check in" value={dayjs('2022-04-17T'+policyData.check_in)} style={{width:'100%'}} onChange={(value) => changePolicyData('check_in',dayjs(value).format('HH:mm:ss'))} />
                    </LocalizationProvider>
                </Grid>
                <Grid item md={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker label="check out" value={dayjs('2022-04-17T'+policyData.check_out)} style={{width:'100%'}} onChange={(value) => changePolicyData('check_out',dayjs(value).format('HH:mm:ss'))}  />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <textarea rows={5} style={{width:'100%'}} value={policyData.policy_terms} className="mt-3" placeholder="Policy..." onChange={(e)=>changePolicyData('policy_terms',e.target.value)}>
            </textarea>
        </>
    );
}

export default Policy;