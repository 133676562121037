import { useEffect,useState } from "react";
import { Modal,Button } from "react-bootstrap";
import {AiOutlineEdit,AiOutlineDelete,AiOutlineClose} from "react-icons/ai";
import Form from "./form";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ADMIN_APIS_LOCATION } from "../../../Constants";

const Location = () => {
    const navigate = useNavigate();
    const [AddModal, setAddModal] = useState(false);
    const [EditModal, setEditModal] = useState(false);
    const [location, setLocation] = useState([]);

    const [locationId, setLocationId] = useState();
  
    const AddModalClose = () => setAddModal(false);
    const AddModalShow = () => setAddModal(true);

    const EditModalClose = () => setEditModal(false);
    const EditModalShow = (id) => {
      setLocationId(id);
      setEditModal(true);
    };

    useEffect(()=>{
      getLocations();
    },[]);

    const getLocations = () => {
      fetch(ADMIN_APIS_LOCATION+'index',{
        method:"get"
      }).then((response)=>{
        response.json().then((res)=>{
          if(res.status == true){
            setLocation(res.data);
          }
        });
      });
    }

    const deleteLocation = (id) => {
      fetch(ADMIN_APIS_LOCATION+'delete',{
        method:"post",
        headers: {
            "Content-Type": "application/json"
          },
        body:JSON.stringify({'location_id':id})
      }).then((response)=>{
        response.json().then((res)=>{
          if(res.status == true){
            getLocations();
            // setHotelType(res.data);
          }
        });
      });
    }

    const moveToEdit = (locationId) => {
      navigate('edit',{state: { locationId:locationId }});
    }

    return (
        <>
        {/* <Button className="btn btn-success mb-2" onClick={(e)=>{gotoForm()}}>Add Location</Button> */}
        <Link to="create" className="btn btn-success mb-2">Add Location</Link>

        <table className="table table-bordered table-hover table-striped table-responsive-sm">
            <thead>
                <tr>
                    <th>Id</th>
                    <th>City</th>
                    <th>Country</th>
                    <th>Latitude</th>
                    <th>Longitude</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
            {location && location.map((item,index) => (
                <tr key={index}>
                    <td>{index+1}</td>
                    <td>{item.location}</td>
                    <td>{item.country}</td>
                    <td>{item.latitude}</td>
                    <td>{item.longitude}</td>
                    <td>{item.status}</td>
                    <td>
                        <AiOutlineEdit className="mr-3 text-primary" onClick={
                          ()=>{moveToEdit(item.id)}
                        }/>
                        <AiOutlineDelete className="text-danger" onClick={()=>{deleteLocation(item.id)}} />
                    </td>
                </tr>
            ))}
            </tbody>
        </table>

        {AddModal === true ? (
        <Modal
          className="fade zoom"
          show={AddModalShow}
          onHide={AddModalClose}
          backdrop="static"
          size="md"
          scrollable={true}
        >
          <Modal.Header>
            <Modal.Title>Add Location</Modal.Title>
            <a href="#" className="close" onClick={AddModalClose}>
              <AiOutlineClose />
            </a>
          </Modal.Header>
          <Modal.Body>
            {/* Add Location */}
            <Form
              closeModal={AddModalClose}
              getLocations={getLocations}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={AddModalClose}>Cancel</Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}

      {/* edit modal */}

      {EditModal === true ? (
        <Modal
          className="fade zoom"
          show={EditModalShow}
          onHide={EditModalClose}
          backdrop="static"
          size="md"
          scrollable={true}
        >
          <Modal.Header>
            <Modal.Title>Edit Location</Modal.Title>
            <a href="#" className="close" onClick={EditModalClose}>
              <AiOutlineClose />
            </a>
          </Modal.Header>
          <Modal.Body>
            {/* Add Location */}
            <Form
              closeModal={EditModalClose}
              getLocations={getLocations}
              locationId={locationId}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={EditModalClose}>Cancel</Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}
        </>
    );
}

export default Location;