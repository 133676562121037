import React from "react";
import CONSTANTS from "../../../Data";
import * as ReuseableComponents from "../../../ReuseableComponents";

const TourMainContent = () => {
	return (
		<>
			<div className="container-fluid margin_60_35">
				<div className="row">
					<div className="col-lg-9">
						<div className="isotope-wrapper">
							<div className="row">
								{/* Tour Card */}
								<ReuseableComponents.TourCard
									featured_tours={
										CONSTANTS.FEATURED_TOURS.featured_tours
									}
								/>
							</div>
						</div>
					</div>
					<aside
						className="col-lg-3"
						id="sidebar">
						{/* Tour Filters */}
						<ReuseableComponents.TourFilters />
					</aside>
				</div>
			</div>
		</>
	);
};

export default TourMainContent;
