import { useState } from "react";
import CustomeTextField from "./CustomeTextField";

const MetaInfo = ({metaData,setMetaData}) => {

    const changeMataData = (key,value) => {
        setMetaData({...metaData,[key]:value});
    }

    return (
        <>
            <CustomeTextField value={metaData.meta_title} objKey='meta_title' changeState={changeMataData} placeholder="Title" />

            <CustomeTextField value={metaData.meta_keywords} objKey='meta_keywords' changeState={changeMataData} placeholder="Keywords" />
            
            <CustomeTextField value={metaData.meta_description} objKey='meta_description' changeState={changeMataData} placeholder="Descriptions" />
        </>
    );
}

export default MetaInfo;