// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import * as React from 'react';
// import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { makeStyles } from '@mui/styles';
import "../../../Styles/index";
import SideForm from './sideform';
import { Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import General from './general';
import Facilities from './facilities';
import Translate from './translate';
import { ADMIN_APIS_ROOM } from "../../../Constants";
import { Button } from 'react-bootstrap';
import { useLocation,useNavigate  } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    customFlexContainer: {
        // Add your custom styling here
        justifyContent: 'space-around',
    },
}));

const Create = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const room_id = location.state ? location.state.roomId : '';
    const [value, setValue] = React.useState('1');

    const classes = useStyles(); // Use the custom styles

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [roomDescription,setRoomDescription] = React.useState('');
    const [sideformData,setSideformData] = React.useState({status:'',price:'',hotel:'',room_type:'',quantity:'',min_stay:'',max_adults:'',max_child:'',extra_beds:'',extra_beds_charges:''});

    const [roomTypes, setRoomTypes] = React.useState([]);
    const [hotels, setHotels] = React.useState([]);
    const [amenities, setAmenities] = React.useState([]);
    const [languages, setLanguages] = React.useState({
        ar:{lang_title:'ARABIC',trans_lang:'ar',trans_desc:''},
        ge:{lang_title:'GERMAN',trans_lang:'ge',trans_desc:''},
        sp:{lang_title:'SPANISH',trans_lang:'sp',trans_desc:''},
        fa:{lang_title:'FARSI',trans_lang:'fa',trans_desc:''},
        fr:{lang_title:'FRENCH',trans_lang:'fr',trans_desc:''},
        ru:{lang_title:'RUSSIAN',trans_lang:'ru',trans_desc:''},
        tu:{lang_title:'TURKISH',trans_lang:'tu',trans_desc:''},
        vi:{lang_title:'VIETNAMESE',trans_lang:'vi',trans_desc:''}
    });

    const [selectedFacilities, setSelectedFacilities] = React.useState([]);
    const [selectAll, setSelectAll] = React.useState(false);

    React.useEffect(() => {
        fetch(ADMIN_APIS_ROOM + 'create', {
            method: 'get'
        }).then((response) => {
            response.json().then((res) => {
                if (res.status == true) {
                    setRoomTypes(res.data.room_types);
                    setHotels(res.data.hotels);
                    setAmenities(res.data.facilities);
                }
            });
        });

        if(room_id){
            fetch(ADMIN_APIS_ROOM + 'edit', {
                method: 'post',
                body:JSON.stringify({room_id:room_id}),
                headers:{
                    'content-type':'application/json'
                }
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status == true) {
                        const genData = res.data;
                        const translation = res.data.room_translation;
                        setRoomDescription(genData.room_desc);
                        setSideformData({
                            status:genData.status,
                            price:genData.price,
                            hotel:genData.hotel_id,
                            room_type:genData.room_type_id,
                            quantity:genData.quantity,
                            min_stay:genData.min_stay,
                            max_adults:genData.max_adults,
                            max_child:genData.max_child,
                            extra_beds:genData.extra_beds,
                            extra_beds_charges:genData.extra_beds_charges
                        });
                        
                        let trans = {};
                        // translation.forEach((obj,index) => {
                            const transLangMap = {
                                'ar': 'ARABIC',
                                'ge': 'GERMAN',
                                'sp': 'SPANISH',
                                'fa': 'FARSI',
                                'fr': 'FRENCH',
                                'ru': 'RUSSIAN',
                                'tu': 'TURKISH',
                                'vi': 'VIETNAMESE',
                            };
                            Object.keys(transLangMap).map((key)=>{
                                if(translation.some(obj => obj.trans_lang == key)){
                                    const dataObj = translation.find(obj1 => obj1.trans_lang == key);
                                    trans[key] = {
                                        lang_title: transLangMap[key],
                                        trans_lang:dataObj.trans_lang,
                                        trans_desc:dataObj.trans_desc
                                    }
                                }else{
                                    trans[key] = {
                                        lang_title: transLangMap[key],
                                        trans_lang:key,
                                        trans_desc:''
                                    }
                                }
                            });
                            // trans[obj.trans_lang] = {
                            //     lang_title: transLangMap[obj.trans_lang],
                            //     trans_lang:obj.trans_lang,
                            //     description:obj.trans_desc
                            // }
                        // });
                        setLanguages(trans);

                        const facilities = res.data.room_amenities;
                        let fac_arr = [];
                        facilities.forEach((key,index) => {
                            fac_arr.push(key.pivot.facility_id);
                        })
                        setSelectedFacilities(fac_arr);
                    }
                });
            });
        }
    }, []);

    const handleCheckboxChange = (event) => {
        const facilityId = parseInt(event.target.value);
        if (event.target.checked) {
        setSelectedFacilities((prevSelected) => [...prevSelected, facilityId]);
        } else {
        setSelectedFacilities((prevSelected) =>
            prevSelected.filter((id) => id !== facilityId)
        );
        }
    };

    const handleSelectAllChange = () => {
        if (!selectAll) {
            setSelectedFacilities(amenities.map((facility) => facility.id));
        } else {
            setSelectedFacilities([]);
        }
        setSelectAll(!selectAll);
    };

    const submitGeneral = () => {
        let data = setBodyData();
        console.log('174 ;; ',data);
        // return;
        fetch(ADMIN_APIS_ROOM + 'store', {
            method: 'post',
            body:JSON.stringify(data),
            headers:{
                'content-type':'application/json'
            }
        }).then((response) => {
            response.json().then((res) => {
                if (res.status == true) {
                    navigate(-1);
                }
            });
        });
    }

    const updateGeneral = () => {
        let data = setBodyData();
        data.room_id = room_id;
        fetch(ADMIN_APIS_ROOM + 'update', {
            method: 'post',
            body:JSON.stringify(data),
            headers:{
                'content-type':'application/json'
            }
        }).then((response) => {
            response.json().then((res) => {
                if (res.status == true) {
                    navigate(-1);
                }
            });
        });
    }

    const setBodyData = () => {
        let data = {
            'room_desc':roomDescription,
            'facilities':selectedFacilities,
            'languages':languages,
            'status':sideformData.status,
            'price':sideformData.price,
            'quantity':sideformData.quantity,
            'min_stay':sideformData.min_stay,
            'max_adults':sideformData.max_adults,
            'max_child':sideformData.max_child,
            'room_type_id':sideformData.room_type,
            'hotel_id':sideformData.hotel,
            'extra_beds':sideformData.extra_beds,
            'extra_beds_charges':sideformData.extra_beds_charges,
        }
        return data;
    }

    // console.log('102   ;  ',generalData);
    // console.log('102   ;  ',metaData);
    // console.log('102   ;  ',policyData);
    // console.log('102   ;  ',contactData);
    // console.log('102   ;  ',languages);
    // console.log('102   ;  ',sideformData);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item md={8}>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange}
                                    className={classes.customFlexContainer}
                                    aria-label="Room Tabs">
                                    <Tab label="General" value="1" />
                                    <Tab label="Facilities" value="2" />
                                    <Tab label="Translate" value="3" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <General roomDescription={roomDescription} setRoomDescription={setRoomDescription} />
                            </TabPanel>
                            <TabPanel value="2">
                                <FormControlLabel control={<Checkbox checked={selectedFacilities.length == amenities.length} onChange={handleSelectAllChange} />} label="Select All" />
                                <br></br>
                                <Facilities amenities={amenities} selectedFacilities={selectedFacilities} handleCheckboxChange={handleCheckboxChange}/>
                            </TabPanel>
                            <TabPanel value="3">
                                <Translate data={languages} setState={setLanguages}/>
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Grid>
                <Grid item md={4} elevation={3}>
                    <SideForm sideformData={sideformData} setSideformData={setSideformData} hotels={hotels} roomTypes={roomTypes} />
                </Grid>
                <Grid item md={8}>
                    <Button variant='primary' className='btn-block' onClick={room_id ? updateGeneral : submitGeneral}>{room_id ? 'Update' : 'Submit'}</Button>
                </Grid>
            </Grid>
        </>
    );
}

export default Create;