import { Select, FormControl, TextField, MenuItem, InputLabel, Grid } from "@mui/material";
import { Modal, Button, Row, Col } from "react-bootstrap";
import CustomeTextField from "./CustomeTextField";
import { useEffect, useState } from "react";
import countryList from 'react-select-country-list'
import { useLocation,useNavigate  } from "react-router-dom";
import { ADMIN_APIS_LOCATION } from "../../../Constants";
const Form = () => {
  // console.log(data);
  const navigate = useNavigate();
  const location = useLocation();
  const locationId = location.state ? location.state.locationId : '';

  const [locationCity, setLocationCity] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [country, setCountry] = useState("");
  const [countryItem, setCountryItem] = useState([]);
  const [status, setStatus] = useState("");
  const [language, setLanguage] = useState({});
  let options = [];
  const handleSubmit = () => {
    // console.log('18:::',name)
    let data = {
      location: locationCity,
      latitude: latitude,
      longitude: longitude,
      country: country,
      status: status,
      languages: language
    }
    fetch(ADMIN_APIS_LOCATION+'store', {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    }).then((response) => {
      response.json().then((res) => {
        if (res.status == true) {
          // closeModal();
          navigate(-1);
        }
      });
    });
  }

  const handleSubmitUpdate = () => {
    // console.log('18:::',name)
    let data = {
      location: locationCity,
      latitude: latitude,
      longitude: longitude,
      country: country,
      status: status,
      languages: language,
      location_id: locationId
    }
    fetch(ADMIN_APIS_LOCATION+'update', {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    }).then((response) => {
      response.json().then((res) => {
        if (res.status == true) {
          // closeModal();
          navigate(-1);
        }
      });
    });
  }

  const handleLanguage = (code, value) => {
    if (language.hasOwnProperty(code) && value == '') {
      const updatedLanguages = Object.keys(language).reduce((acc, key) => {
        if (key !== code) {
          acc[key] = language[key];
        }
        return acc;
      }, {});
      setLanguage(updatedLanguages);
      return;
    }
    setLanguage(prevLanguage => ({ ...prevLanguage, [code]: { trans_lang: code, loc_name: value } }))
  }

  useEffect(() => {
    if (locationId) {
      fetch(ADMIN_APIS_LOCATION+'edit', {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ location_id: locationId })
      }).then((response) => {
        response.json().then((res) => {
          if (res.status == true) {
            setLocationCity(res.data.location);
            setLatitude(res.data.latitude);
            setLongitude(res.data.longitude);
            setCountry(res.data.country);
            setStatus(res.data.status);
            let lanObj = {};
            res.data.translation.forEach(element => {
              lanObj[element.trans_lang] = element;
            });
            setLanguage(lanObj);
          }
        });
      });
    }
    options = countryList().getData();
    
    setCountryItem(options);
  }, []);
  

  return (
    <>
        {locationId ? <h4>Edit Location</h4> : <h4>Add Location</h4>}
        <Grid container columnSpacing={{ md: "10" }}>
          <Grid item md={6}>
            <FormControl fullWidth className="mt-3">
              <InputLabel id="demo-simple-select-label">Country</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                {countryItem.map(option => {
                      return (
                        <MenuItem key={option.value} value={option.label}>
                          {option.label}
                        </MenuItem>
                      )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl fullWidth className="mt-3">
              <TextField id="outlined-basic" label="Location" variant="outlined" onChange={(e) => setLocationCity(e.target.value)} value={locationCity} />
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl fullWidth className="mt-3">
              <TextField id="outlined-basic" label="Latitude" variant="outlined" onChange={(e) => setLatitude(e.target.value)} value={latitude} />
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl fullWidth className="mt-3">
              <TextField id="outlined-basic" label="Longitude" variant="outlined" onChange={(e) => setLongitude(e.target.value)} value={longitude} />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={6}>
            <FormControl fullWidth className="mt-3">
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value={"Yes"}>Enable</MenuItem>
                <MenuItem value={"No"}>Disable</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <hr></hr>
        <Grid container columnSpacing={{ md: "10" }}>
          <Grid item md={6}>
            <CustomeTextField value={language.ar ? language.ar.loc_name : ''} code="ar" setState={handleLanguage} placeholder="Name in Arabic" />
          </Grid>
          <Grid item md={6}>
            <CustomeTextField value={language.de ? language.de.loc_name : ''} code="de" setState={handleLanguage} placeholder="Name in German" />
          </Grid>
          <Grid item md={6}>
            <CustomeTextField value={language.es ? language.es.loc_name : ''} code="es" setState={handleLanguage} placeholder="Name in Spanish" />
          </Grid>
          <Grid item md={6}>
            <CustomeTextField value={language.fa ? language.fa.loc_name : ''} code="fa" setState={handleLanguage} placeholder="Name in Farsi" />
          </Grid>
          <Grid item md={6}>
            <CustomeTextField value={language.fr ? language.fr.loc_name : ''} code="fr" setState={handleLanguage} placeholder="Name in French" />
          </Grid>
          <Grid item md={6}>
            <CustomeTextField value={language.ru ? language.ru.loc_name : ''} code="ru" setState={handleLanguage} placeholder="Name in Russian" />
          </Grid>
          <Grid item md={6}>
            <CustomeTextField value={language.tr ? language.tr.loc_name : ''} code="tr" setState={handleLanguage} placeholder="Name in Turkish" />
          </Grid>
          <Grid item md={6}>
            <CustomeTextField value={language.vi ? language.vi.loc_name : ''} code="vi" setState={handleLanguage} placeholder="Name in Vietnamese" />
          </Grid>
        </Grid>

        <Button onClick={locationId ? handleSubmitUpdate : handleSubmit} className="mt-3" variant="primary">Submit</Button>

    </>
  );
}

export default Form;