import { createBrowserRouter } from "react-router-dom";
import * as Layouts from "../Layouts";
import * as RoutePages from "../Pages";

const Router = [
	// Frontend...
	{
		path: "/",
		element: <Layouts.FrontendLayout />,
		errorElement: <h1>error!</h1>,
		children: [
			// Home...
			{
				path: "/",
				element: <RoutePages.Home />,
			},

			// Hotels...
			{
				path: "hotels",
				element: <RoutePages.Hotels />,
			},

			{
				path: "hotels/detail",
				element: <RoutePages.HotelDetail />,
			},

			{
				path: "hotels/booking",
				element: <RoutePages.HotelBooking />,
			},

			{
				path: "hotels/invoice",
				element: <RoutePages.HotelInvoice />,
			},

			// Tours...
			{
				path: "tours",
				element: <RoutePages.Tours />,
			},

			{
				path: "tours/detail",
				element: <RoutePages.TourDetail />,
			},

			{
				path: "tours/invoice",
				element: <RoutePages.TourInvoice />,
			},

			// Cars...
			{
				path: "cars",
				element: <RoutePages.Cars />,
			},

			{
				path: "cars/detail",
				element: <RoutePages.CarDetail />,
			},

			{
				path: "cars/booking",
				element: <RoutePages.CarBooking />,
			},

			{
				path: "cars/invoice",
				element: <RoutePages.CarInvoice />,
			},

			// Contact Us...
			{
				path: "contact_us",
				element: <RoutePages.ContactUs />,
			},
		],
	},

	// Auth...
	{
		path: "/",
		element: <Layouts.AuthLayout />,
		errorElement: <h1>error!</h1>,
		children: [
			// Customer Register...
			{
				path: "/register",
				element: <RoutePages.CustomerRegister />,
			},

			// Customer Login...
			{
				path: "/login",
				element: <RoutePages.CustomerLogin />,
			},

			// Admin Login...
			{
				path: "admin/login",
				element: <RoutePages.AdminLogin />,
			},
		],
	},

	//dashboard
	{
		path: "/",
		element: <Layouts.DashboardLayout />,
		errorElement: <h1>error!</h1>,
		children: [
			{
				path: "dashboard",
				element:<RoutePages.Dashboard />,
			},

			{
				path: "dashboard/hotels",
				element: <RoutePages.HotelList />,
			},

			{
				path: "dashboard/hotels/create",
				element: <RoutePages.HotelCreate />,
			},

			{
				path: "dashboard/hotels/edit",
				element: <RoutePages.HotelCreate />,
			},

			{
				path: "dashboard/hotels/settings",
				element: <RoutePages.Settings />,
			},

			{
				path: "dashboard/locations",
				element: <RoutePages.Location />,
			},

			{
				path: "dashboard/locations/create",
				element: <RoutePages.CreateLocation />,
			},

			{
				path: "dashboard/locations/edit",
				element: <RoutePages.CreateLocation />,
			},

			{
				path: "dashboard/rooms",
				element: <RoutePages.RoomList />,
			},

			{
				path: "dashboard/rooms/create",
				element: <RoutePages.RoomCreate />,
			},

			{
				path: "dashboard/rooms/edit",
				element: <RoutePages.RoomCreate />,
			},
		],
	},
];

const Route = createBrowserRouter(Router);

export default Route;

