import React from "react";
import CONSTANTS from "../../../Data";
import * as ReuseableComponents from "../../../ReuseableComponents";

const HotelAvailableRoomsDetail = () => {
	return (
		<>
			<td
				colSpan={3}
				className="w-100">
				<div className="container-fluid bg-light rounded p-3">
					{/* Images */}
					<div className="row">
						<div className="col-sm-12 col-md-4">
							<img
								src="/backend/img/additional/hotels/hotel_1.jpg"
								alt="Hotel Image"
								className="card_image rounded"
							/>
						</div>
						<div className="col-sm-12 col-md-4 mt-3 mt-md-0">
							<img
								src="/backend/img/additional/hotels/hotel_1.jpg"
								alt="Hotel Image"
								className="card_image rounded"
							/>
						</div>
						<div className="col-sm-12 col-md-4 mt-3 mt-md-0">
							<img
								src="/backend/img/additional/hotels/hotel_1.jpg"
								alt="Hotel Image"
								className="card_image rounded"
							/>
						</div>
					</div>

					{/* Description */}
					<div className="row mt-3">
						<div className="col-sm-12">
							<div className="main_title_3">
								<span>
									<em></em>
								</span>
								<h2>Description</h2>
							</div>
							<p className="text_justify">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Nullam viverra rutrum aliquam.
								Sed posuere quam eget tellus dictum, et suscipit
								tellus pellentesque. Nullam elementum dui sit
								amet feugiat placerat. In aliquam dignissim
								convallis. Sed vestibulum erat turpis, nec
								finibus nisl ullamcorper semper. Maecenas ante
								odio, fermentum at nunc sit amet, posuere
								consectetur nibh. Donec vel nibh posuere, semper
								augue at, eleifend quam. Nulla at consequat
								ante. Praesent fermentum leo sed scelerisque
								tempus. Duis arcu nunc, accumsan at hendrerit
								sed, euismod nec sem. Donec vel pellentesque
								dolor. Mauris finibus pellentesque lobortis.
								Aenean semper nisi non congue cursus.
								Pellentesque fringilla quam sodales, mattis
								lorem quis, elementum nulla. Integer at
								facilisis libero. Aenean venenatis ac lectus eu
								bibendum. Praesent enim urna, consequat ac
								ultricies at, semper et quam. Vestibulum non
								urna tristique, posuere leo in, gravida purus.
								Phasellus pretium ac justo vel interdum.
								Vestibulum ante ipsum primis in faucibus orci
								luctus et ultrices.
							</p>
						</div>
					</div>

					{/* Room Amenities */}
					<div className="row mt-3">
						<div className="col-sm-12">
							<div className="main_title_3">
								<span>
									<em></em>
								</span>
								<h2>Room Amenities</h2>
							</div>

							<div className="row">
								{CONSTANTS.HOTEL_AMENITIES.hotel_amenities.map(
									(item, index) => (
										<div
											className="col-sm-12 col-md-6"
											key={index}>
											<p className="m-0 p-0 mb-2">
												<ReuseableComponents.ReactIcons.CHECK className="check_icon" />
												{item.label}
											</p>
										</div>
									),
								)}
							</div>
						</div>
					</div>
				</div>
			</td>
		</>
	);
};

export default HotelAvailableRoomsDetail;
