import React from "react";
import * as Components from "../../../../Components";

const HotelBooking = () => {
	// Site Title...
	document.title = "Hotel Booking || Travel Craze";

	return (
		<>
			{/* Top Banner */}
			<Components.TopBanner text="Hotel Booking" />

			{/* Hotel Booking Main Content */}
			<Components.HotelBookingMainContent />
		</>
	);
};

export default HotelBooking;
