import React from "react";
import BannerImage from "../../../Assets/backend/img/additional/home_banner.jpg";

const TopBanner = (props) => {
	return (
		<>
			<section
				className="hero_in hotels_detail"
				style={{
					background: `url(${BannerImage})`,
					backgroundPosition: "center",
					backgroundSize: "100% 100%",
					height: "620px",
				}}>
				<div className="wrapper">
					<div className="container">
						<h1 className="fadeInUp">
							<span />
							{props?.text}
						</h1>
					</div>
				</div>
			</section>
		</>
	);
};

export default TopBanner;
