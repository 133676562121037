import React from "react";
import CONSTANTS from "../../../Data";
import * as ReuseableComponents from "../../../ReuseableComponents";

const CarMainContent = () => {
	return (
		<>
			<div className="container-fluid margin_60_35">
				<div className="row">
					<div className="col-lg-9">
						<div className="isotope-wrapper">
							<div className="row">
								{/* Car Card */}
								<ReuseableComponents.CarCard
									featured_cars={
										CONSTANTS.FEATURED_CARS.featured_cars
									}
								/>
							</div>
						</div>
					</div>

					<aside
						className="col-lg-3"
						id="sidebar">
						{/* Car Filters */}
						<ReuseableComponents.CarFilters />
					</aside>
				</div>
			</div>
		</>
	);
};

export default CarMainContent;
