import React from "react";
import * as Components from "../../../../Components";

const CarInvoice = () => {
	// Site Title...
	document.title = "Car Invoice || Travel Craze";

	return (
		<>
			{/* Top Banner */}
			<Components.TopBanner text="Car Invoice" />

			{/* Car Invoice Main Content */}
			<Components.CarInvoiceMainContent />
		</>
	);
};

export default CarInvoice;
