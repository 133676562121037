import React from "react";
import * as Components from "../../../../Components";

const TourInvoice = () => {
	// Site Title...
	document.title = "Tour Invoice || Travel Craze";

	return (
		<>
			{/* Top Banner */}
			<Components.TopBanner text="Tour Invoice" />

			{/* Tour Invoice Main Content */}
			<Components.TourInvoiceMainContent />
		</>
	);
};

export default TourInvoice;
