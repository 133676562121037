import React from "react";
import * as Hooks from "../../../Hooks";
import * as ReactIcons from "../../../ReuseableComponents/index";
import { Button } from "react-bootstrap";

const Header = () => {
	// States...
	const [mobile_menu, setMobileMenu] = Hooks.useState(false);

	// Location...
	const location = Hooks.useLocation();

	// Navigate...
	const navigate = Hooks.useNavigate();

	// On Navigate Register Page
	const onNavigateRegisterPage = () => {
		navigate("/register");
	};

	// On Navigate Login Page
	const onNavigateLoginPage = () => {
		navigate("/login");
	};

	// useEfferct...
	Hooks.useEffect(() => {
		if (location.pathname !== "/") {
			if (mobile_menu) {
				document.querySelector(".hamburger").classList.add("is-active");
				document
					.querySelector(".main-menu.mm-offcanvas")
					.classList.add("mm-opened");
			} else {
				document
					.querySelector(".hamburger")
					.classList.remove("is-active");
				document
					.querySelector(".main-menu.mm-offcanvas")
					.classList.remove("mm-opened");
			}
		}
	}, [mobile_menu]);

	return (
		<div>
			{/* Logo */}
			<div id="logo">
				<Hooks.Link to="/">
					<img
						src="/backend/logo.png"
						width="250"
						alt="Logo"
						className="logo_normal"
					/>
					<img
						src="/backend/logo.png"
						width="150"
						alt="Logo"
						className="logo_sticky"
					/>
				</Hooks.Link>
			</div>

			{location.pathname === "/" ? (
				<>
					{/* Top Menu */}
					<ul
						id="top_menu"
						className="d-none d-md-block">
						{/* Register */}
						<li>
							<Button
								className="btn_1 rounded"
								onClick={onNavigateRegisterPage}>
								Register
							</Button>
						</li>

						{/* Login */}
						<li>
							<Button
								className="btn_1 rounded"
								onClick={onNavigateLoginPage}>
								Login
							</Button>
						</li>
					</ul>

					{/* Mobile Menu */}
					<ul className="login_signup">
						<li>
							<Hooks.Link
								to="/"
								className="text-light register">
								Register /
							</Hooks.Link>

							<Hooks.Link
								to="/"
								className="text-light ms-1">
								Log In
							</Hooks.Link>
						</li>
					</ul>
				</>
			) : (
				<>
					<Hooks.Link
						to="#"
						className="btn_mobile"
						onClick={() => setMobileMenu(!mobile_menu)}>
						<div
							className="hamburger hamburger--spin"
							id="hamburger">
							<div className="hamburger-box">
								<div className="hamburger-inner"></div>
							</div>
						</div>
					</Hooks.Link>{" "}
					<nav
						id="menu"
						className="main-menu">
						<ul>
							{/* Home */}
							<li>
								<Hooks.Link
									to="/"
									className={`menu_header_item ${
										location.pathname.split("/")[1] ===
											"/" && "active"
									}`}>
									Home
								</Hooks.Link>
							</li>

							{/* Hotels */}
							<li>
								<Hooks.Link
									to="/hotels"
									className={`menu_header_item ${
										location.pathname.split("/")[1] ===
											"hotels" && "active"
									}`}>
									Hotels
								</Hooks.Link>
							</li>

							{/* Tours */}
							<li>
								<Hooks.Link
									to="/tours"
									className={`menu_header_item ${
										location.pathname.split("/")[1] ===
											"tours" && "active"
									}`}>
									Tours
								</Hooks.Link>
							</li>

							{/* Cars */}
							<li>
								<Hooks.Link
									to="/cars"
									className={`menu_header_item ${
										location.pathname.split("/")[1] ===
											"cars" && "active"
									}`}>
									Cars
								</Hooks.Link>
							</li>

							{/* Contact Us */}
							<li>
								<Hooks.Link
									to="/contact_us"
									className={`menu_header_item ${
										location.pathname.split("/")[1] ===
											"contact_us" && "active"
									}`}>
									Contact Us
								</Hooks.Link>
							</li>

							{/* User Profile */}

							<li>
								<span>
									<Hooks.Link to="#">
										<img
											src="/backend/img/avatar.jpg"
											alt="Avatar"
											width={50}
											height={50}
											className="rounded-circle"
										/>
									</Hooks.Link>
								</span>
								<ul>
									{/* Login */}
									<li>
										<Hooks.Link to="/">
											{/* <ReactIcons.ReactIcons.SOLID_USER_CIRCLE className="header_dropdown_icons me-2" /> */}
											Login
										</Hooks.Link>
									</li>

									{/* Register */}
									<li>
										<Hooks.Link to="/">
											{/* <ReactIcons.ReactIcons.LOG_IN_CIRCLE className="header_dropdown_icons me-2" /> */}
											Register
										</Hooks.Link>
									</li>
								</ul>
							</li>
						</ul>
					</nav>
					{/* Mobile Menu */}
					<nav
						id="mm-menu"
						className="main-menu mm-menu mm-offcanvas mm-hasnavbar-bottom-1 mm-pagedim-black"
						aria-hidden="true">
						<div className="mm-panels">
							<div
								className="mm-panel mm-hasnavbar mm-opened"
								id="mm-1">
								<div className="mm-navbar">
									<Hooks.Link
										to="#"
										className="mm-title">
										MENU
									</Hooks.Link>
								</div>
								<ul className="mm-listview">
									{/* Home */}
									<li
										className={`${
											location.pathname.split("/")[1] ===
												"/" && "active"
										}`}>
										<Hooks.Link to="/">Home</Hooks.Link>
									</li>

									{/* Hotels */}
									<li
										className={`${
											location.pathname.split("/")[1] ===
												"hotels" && "active"
										}`}>
										<Hooks.Link to="/hotels">
											Hotels
										</Hooks.Link>
									</li>

									{/* Tours */}
									<li
										className={`${
											location.pathname.split("/")[1] ===
												"tours" && "active"
										}`}>
										<Hooks.Link to="/tours">
											Tours
										</Hooks.Link>
									</li>

									{/* Cars */}
									<li
										className={`${
											location.pathname.split("/")[1] ===
												"cars" && "active"
										}`}>
										<Hooks.Link to="/cars">Cars</Hooks.Link>
									</li>

									{/* Contact Us */}
									<li
										className={`${
											location.pathname.split("/")[1] ===
												"contact_us" && "active"
										}`}>
										<Hooks.Link to="/contact_us">
											Contact Us
										</Hooks.Link>
									</li>
								</ul>
							</div>
						</div>

						<div className="mm-navbars-bottom">
							<div className="mm-navbar mm-navbar-size-1">
								© 2023 Travel Craze.
							</div>
						</div>
					</nav>
				</>
			)}
		</div>
	);
};

export default Header;
