import React from "react";
import * as Hooks from "../../../Hooks";
import * as ReactIcons from "../../index";
import CONSTANTS from "../../../Data";

const CarFilters = () => {
	// States...
	const [collapse_filters, setCollapseFilters] = Hooks.useState(true);

	// useEfferct...
	Hooks.useEffect(() => {
		if (collapse_filters) {
			document.querySelector(".collapse").classList.add("show");
			document
				.querySelector("#filters_col_bt")
				.classList.remove("collapsed");
		} else {
			document.querySelector(".collapse").classList.remove("show");
			document
				.querySelector("#filters_col_bt")
				.classList.add("collapsed");
		}
	}, [collapse_filters]);
	return (
		<>
			<div id="filters_col">
				<div>
					{/* Collapse Button */}
					<Hooks.Link
						to="#"
						data-bs-toggle="collapse"
						aria-expanded="false"
						aria-controls="collapseFilters"
						id="filters_col_bt"
						onClick={() => setCollapseFilters(!collapse_filters)}>
						Filter Search{" "}
					</Hooks.Link>

					{/* Filters */}
					<div
						className="collapse show"
						id="collapseFilters">
						{/* Star Grade */}
						<div className="filter_type">
							<h6>Star Grade</h6>
							<ul>
								<li>
									<label className="container_check">
										<span className="cat_star">
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon gray ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon gray ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon gray ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon gray ms-1" />
										</span>
										<input
											type="radio"
											name="star_rating"
										/>
										<span className="checkmark radio_filter mt-1" />
									</label>
								</li>

								<li>
									<label className="container_check">
										<span className="cat_star">
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon gray ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon gray ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon gray ms-1" />
										</span>
										<input
											type="radio"
											name="star_rating"
										/>
										<span className="checkmark radio_filter mt-1" />
									</label>
								</li>

								<li>
									<label className="container_check">
										<span className="cat_star">
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon gray ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon gray ms-1" />
										</span>
										<input
											type="radio"
											name="star_rating"
										/>
										<span className="checkmark radio_filter mt-1" />
									</label>
								</li>

								<li>
									<label className="container_check">
										<span className="cat_star">
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon gray ms-1" />
										</span>
										<input
											type="radio"
											name="star_rating"
										/>
										<span className="checkmark radio_filter mt-1" />
									</label>
								</li>

								<li>
									<label className="container_check">
										<span className="cat_star">
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow ms-1" />
										</span>
										<input
											type="radio"
											name="star_rating"
										/>
										<span className="checkmark radio_filter mt-1" />
									</label>
								</li>
							</ul>
						</div>

						{/* Car Types */}
						<div className="filter_type">
							<h6>Car Types</h6>
							<ul>
								{CONSTANTS.CARS_TYPE.cars_type.map(
									(item, index) => (
										<li key={index}>
											<label className="container_check">
												{item.label}
												<input type="checkbox" />
												<span className="checkmark check_box mt-1" />
											</label>
										</li>
									),
								)}
							</ul>
						</div>

						{/* Airport Pickup */}
						<div className="filter_type">
							<h6>Airport Pickup</h6>
							<ul>
								<li>
									<label className="container_check">
										Yes
										<input
											type="radio"
											name="star_rating"
										/>
										<span className="checkmark radio_filter mt-1" />
									</label>
								</li>

								<li>
									<label className="container_check">
										No
										<input
											type="radio"
											name="star_rating"
										/>
										<span className="checkmark radio_filter mt-1" />
									</label>
								</li>
							</ul>
						</div>

						{/* Search Button */}
						<div className="filter_type filter_button_bottom_wrapper">
							<Hooks.Link
								to="/"
								className="btn_1 full-width rounded">
								Search
							</Hooks.Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CarFilters;
