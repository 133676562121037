import * as FeaturedCardsData from "./FeaturedCardsData/index.json"
import ToursType from "./ToursType/index.json"
import PropertyType from "./PropertyType/index.json"
import HotelAmenities from "./HotelAmenities/index.json"
import CarsType from "./CarsType/index.json"
import CountArray from "./CountArray/index.json"
import TourFacilities from "./TourFacilities/index.json"

const CONSTANTS = {
	FEATURED_HOTELS: FeaturedCardsData,
	FEATURED_TOURS: FeaturedCardsData,
	FEATURED_CARS: FeaturedCardsData,
	TOURS_TYPES: ToursType,
	PROPERTY_TYPES: PropertyType,
	HOTEL_AMENITIES: HotelAmenities,
	CARS_TYPE: CarsType,
	COUNT_ARRAY: CountArray,
	TOUR_FACILITIES: TourFacilities

}

export default CONSTANTS