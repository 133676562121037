// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { makeStyles } from '@mui/styles';
import HotelType from '../../../ReuseableComponents/Backend/HotelType';
import RoomType from '../../../ReuseableComponents/Backend/RoomType';
import HotelAmenities from '../../../ReuseableComponents/Backend/HotelAmenities';
import RoomAmenities from '../../../ReuseableComponents/Backend/RoomAmenities';
import "../../../Styles/index";

const useStyles = makeStyles((theme) => ({
    customFlexContainer: {
      // Add your custom styling here
      justifyContent: 'space-around', 
    },
  }));

const Settings = () => {
    const [value, setValue] = React.useState('1');

    const classes = useStyles(); // Use the custom styles

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange}
                        className={classes.customFlexContainer}
                        aria-label="Settings Tabs">
                            <Tab label="General" value="1" />
                            <Tab label="Hotel Types" value="2" />
                            <Tab label="Room Types" value="3" />
                            <Tab label="Payment Methods" value="4" />
                            <Tab label="Hotel Amenities" value="5" />
                            <Tab label="Room Amenities" value="6" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">General</TabPanel>
                    <TabPanel value="2">
                        <HotelType />
                    </TabPanel>
                    <TabPanel value="3">
                        <RoomType />
                    </TabPanel>
                    <TabPanel value="4">Payment Methods</TabPanel>
                    <TabPanel value="5">
                        <HotelAmenities />
                    </TabPanel>
                    <TabPanel value="6">
                        <RoomAmenities />
                    </TabPanel>
                </TabContext>
            </Box>
        </>
    );
}

export default Settings;