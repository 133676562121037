import React from "react";
import * as Hooks from "../../../Hooks";
import { Badge, Button, Stack } from "react-bootstrap";
import * as Components from "../../../Components";
import * as ReuseableComponents from "../../../ReuseableComponents";

const TourDetailsPrivacyPoicy = () => {
	// States...
	const [write_review_card, setWriteReviewCard] = Hooks.useState(false);
	const [open_modal, setOpenModal] = Hooks.useState(false);

	// On Add To Whish List...
	const onAddToWhishList = () => {
		return setOpenModal(true);
	};

	return (
		<>
			{/* Table */}
			<table className="table privacy_policy_table">
				<tbody>
					<tr>
						<td className="privacy_policy_table_heading">
							Privacy Policy
						</td>
						<td className="text_justify">
							Prices include return economy-class flights from on
							Qatar Airways. Prices are quoted on a twin-sharing
							basis in a standard room with inclusions listed
							above. Additional options are available upon
							request. Departures are fixed for 23 September 2015,
							no amendments or changes will be possible. Prices
							are correct at the time of publication and are
							subject to change without notice depending on travel
							dates and availability. Easy monthly payment plans
							are available from select participating banks
							through Musafir Holidays retail outlets and are
							subject to approval. All photos and videos shown are
							illustrative. Actual services may differ. Terms
							apply.
						</td>
					</tr>

					<tr>
						<td className="privacy_policy_table_heading">
							Payment Types
						</td>
						<td className="text_justify">
							{/* Credit Card - Wire Transfer - Master / Visa Card - */}
							{/* Pay On Arrival  */}
							<Stack
								direction="horizontal"
								gap={2}>
								<Badge className="privacy_policy_table_primary_badge">
									Credit Card
								</Badge>
								<Badge className="privacy_policy_table_warning_badge">
									Wire Transfer
								</Badge>
								<Badge className="privacy_policy_table_dark_badge">
									American Express
								</Badge>
								<Badge className="privacy_policy_table_success_badge">
									Master / Visa Card
								</Badge>
							</Stack>
						</td>
					</tr>

					<tr>
						<td className="privacy_policy_table_heading w-100" colSpan={2}>
							<div className="d-flex">
								<ReuseableComponents.ReactIcons.SUN_FILL className="privacy_policy_table_sun_icon" />{" "}
								Days:{" "}
								<span className="font_weight_normal">00</span>
								<ReuseableComponents.ReactIcons.MOON_FILL className="privacy_policy_table_moon_icon ms-4" />{" "}
								Days:{" "}
								<span className="font_weight_normal">00</span>
								<ReuseableComponents.ReactIcons.MOON_FILL className="privacy_policy_table_moon_icon ms-4" />{" "}
								Hours:{" "}
								<span className="font_weight_normal">00</span>
							</div>
						</td>
					</tr>
				</tbody>
			</table>

			{/* Buttons */}
			<div className="d-flex mt-3 justify-content-center">
				<Button
					className="btn_1 rounded"
					onClick={() => setWriteReviewCard(!write_review_card)}>
					Write Review
				</Button>
				<Button
					className="btn_2 rounded ms-2"
					onClick={onAddToWhishList}>
					Add To Whishlist
				</Button>
			</div>

			{/* Write Review Card */}
			{write_review_card === true && (
				<div className="box_detail mt-3">
					<div className="row">
						{/* Form */}
						<div className="col-sm-12 mt-3 mt-md-0">
							<div className="row">
								{/* Your Name */}
								<div className="col-sm-12 col-md-6">
									<div className="form-group">
										<input
											type="text"
											id="name"
											name="name"
											className="form-control"
											placeholder="Your Name"
										/>
									</div>
								</div>

								{/* Your Email Address */}
								<div className="col-sm-12 col-md-6 mt-3 mt-md-0">
									<div className="form-group">
										<input
											type="text"
											id="email_address"
											name="email_address"
											className="form-control"
											placeholder="Your Email Address"
										/>
									</div>
								</div>
							</div>

							<div className="row">
								{/* Your Review */}
								<div className="col-sm-12">
									<div className="form-group">
										<textarea
											className="form-control text_arae"
											id="review"
											name="review"
											placeholder="Your Review"></textarea>
									</div>
								</div>
							</div>

							<small className="text-danger">
								<strong>Please Note: </strong> Once review added
								then can't be deleted or updated!
							</small>

							<div className="d-grid">
								<Button className="btn_2 mx-auto rounded w-50 mt-2">
									Submit
								</Button>
							</div>
						</div>
					</div>
				</div>
			)}

			{/* Open Modal */}
			{open_modal && (
				<Components.DefaultModal
					modalOpen={() => setOpenModal(true)}
					modalClose={() => setOpenModal(false)}
				/>
			)}
		</>
	);
};

export default TourDetailsPrivacyPoicy;
