import React from "react";
import * as ReuseableComponents from "../../../ReuseableComponents";

const Footer = () => {
	return (
		<>
			{/* Footer Top */}
			<ReuseableComponents.FooterTop />

			<hr className="mt-5" />

			{/* Footer Bottom */}
			<ReuseableComponents.FooterBottom />
		</>
	);
};

export default Footer;
