import { useEffect,useState } from "react";
import { Modal,Button } from "react-bootstrap";
import {AiOutlineEdit,AiOutlineDelete,AiOutlineClose} from "react-icons/ai";
// import Form from "./form";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ADMIN_APIS_HOTEL } from "../../../Constants";

const Hotel = () => {
    const navigate = useNavigate();
    const [hotel, setHotel] = useState([]);

    const [hotelId, setHotelId] = useState();

    useEffect(()=>{
        getHotels();
    },[]);

    const getHotels = () => {
      fetch(ADMIN_APIS_HOTEL+'index',{
        method:"get"
      }).then((response)=>{
        response.json().then((res)=>{
          if(res.status == true){
            setHotel(res.data);
          }
        });
      });
    }

    const deleteHotel = (id) => {
      fetch(ADMIN_APIS_HOTEL+'delete',{
        method:"post",
        headers: {
            "Content-Type": "application/json"
          },
        body:JSON.stringify({'hotel_id':id})
      }).then((response)=>{
        response.json().then((res)=>{
          if(res.status == true){
            getHotels();
            // setHotelType(res.data);
          }
        });
      });
    }

    const moveToEdit = (hotelId) => {
      navigate('edit',{state: { hotelId:hotelId }});
    }

    return (
        <>
        {/* <Button className="btn btn-success mb-2" onClick={(e)=>{gotoForm()}}>Add Location</Button> */}
        <Link to="create" className="btn btn-success mb-2">Add Hotel</Link>

        <table className="table table-bordered table-hover table-striped table-responsive-sm">
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Stars</th>
                    <th>Location</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
            {hotel && hotel.map((item,index) => (
                <tr key={index}>
                    <td>{index+1}</td>
                    <td>{item.name}</td>
                    <td>{item.stars}</td>
                    <td>{item.location.country}</td>
                    <td>{item.status}</td>
                    <td>
                        <AiOutlineEdit className="mr-3 text-primary" onClick={
                          ()=>{moveToEdit(item.id)}
                        }/>
                        <AiOutlineDelete className="text-danger" onClick={()=>{deleteHotel(item.id)}} />
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
        </>
    );
}

export default Hotel;