import React from "react";
import * as Components from "../../../../Components";

const HotelInvoice = () => {
	// Site Title...
	document.title = "Hotel Invoice || Travel Craze";

	return (
		<>
			{/* Top Banner */}
			<Components.TopBanner text="Hotel Invoice" />

			{/* Hotel Invoice Main Content */}
			<Components.HotelInvoiceMainContent />
		</>
	);
};

export default HotelInvoice;
