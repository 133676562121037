import { Select,FormControl,MenuItem,InputLabel } from "@mui/material";

const ReuseableSelect = ({label,value,objKey,changeState,data}) => {
    return (
        <>
            <FormControl fullWidth className="mt-3">
                <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={label}
                    value={value}
                    onChange={(e)=>changeState(objKey,e.target.value)}
                >
                    {data()}
                </Select>
            </FormControl>
        </>
    );
}

export default ReuseableSelect;