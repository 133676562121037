import { useState,useEffect } from "react";
import { Modal,Button } from "react-bootstrap";
import {AiOutlineEdit,AiOutlineDelete,AiOutlineClose} from "react-icons/ai";
import Form from "./form";
import { ADMIN_APIS_ROOMAMENITIES } from "../../../Constants";

const RoomAmenity = () => {
    const [AddModal, setAddModal] = useState(false);
    const [EditModal, setEditModal] = useState(false);
    const [roomAmenity, setRoomAmenity] = useState([]);

    const [roomAmenityId, setRoomAmenityId] = useState();
  
    const AddModalClose = () => setAddModal(false);
    const AddModalShow = () => setAddModal(true);

    const EditModalClose = () => setEditModal(false);
    const EditModalShow = (id) => {
      setRoomAmenityId(id);
      setEditModal(true);
    };
    useEffect(()=>{
      getRoomAmenities();
    },[]);

    const getRoomAmenities = () => {
      fetch(ADMIN_APIS_ROOMAMENITIES+'index',{
        method:"get"
      }).then((response)=>{
        response.json().then((res)=>{
          if(res.status == true){
            setRoomAmenity(res.data);
          }
        });
      });
    }

    const deleteAmenity = (id) => {
      fetch(ADMIN_APIS_ROOMAMENITIES+'delete',{
        method:"post",
        headers: {
            "Content-Type": "application/json"
          },
        body:JSON.stringify({'room_amenity_id':id})
      }).then((response)=>{
        response.json().then((res)=>{
          if(res.status == true){
            getRoomAmenities();
            // setHotelType(res.data);
          }
        });
      });
    }

    return (
        <>
        <Button className="btn btn-success mb-2" onClick={AddModalShow}>Add Room Amenity</Button>

        <table className="table table-bordered table-hover table-striped table-responsive-sm">
            <thead>
                <tr>
                    <th>id</th>
                    <th>Name</th>
                    <th>Selected</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
            {roomAmenity && roomAmenity.map((item,index) => (
                <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.selected}</td>
                    <td>{item.status}</td>
                    <td>
                        <AiOutlineEdit className="mr-3 text-primary" onClick={()=>EditModalShow(item.id)}/>
                        <AiOutlineDelete className="text-danger" onClick={()=>{deleteAmenity(item.id)}} />
                    </td>
                </tr>
            ))}
            </tbody>
        </table>

        {AddModal === true ? (
        <Modal
          className="fade zoom"
          show={AddModalShow}
          onHide={AddModalClose}
          backdrop="static"
          size="md"
          scrollable={true}
        >
          <Modal.Header>
            <Modal.Title>Add Room Amenity</Modal.Title>
            <a href="#" className="close" onClick={AddModalClose}>
              <AiOutlineClose />
            </a>
          </Modal.Header>
          <Modal.Body>
            {/* Add Room Amenity */}
            <Form
              closeModal={AddModalClose}
              getRoomAmenities={getRoomAmenities}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={AddModalClose}>Cancel</Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}

      {/* edit modal */}

      {EditModal === true ? (
            <Modal
              className="fade zoom"
              show={EditModalShow}
              onHide={EditModalClose}
              backdrop="static"
              size="md"
              scrollable={true}
            >
              <Modal.Header>
                <Modal.Title>Edit Room Amenity</Modal.Title>
                <a href="#" className="close" onClick={EditModalClose}>
                  <AiOutlineClose />
                </a>
              </Modal.Header>
              <Modal.Body>
                {/* Add Room Amenity */}
                <Form
                  closeModal={EditModalClose}
                  getRoomAmenities={getRoomAmenities}
                  roomAmenityId={roomAmenityId}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="light" onClick={EditModalClose}>Cancel</Button>
              </Modal.Footer>
            </Modal>
          ) : (
            ""
          )}
        </>
    );
}

export default RoomAmenity;