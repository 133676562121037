import { useEffect,useState } from "react";
import { Modal,Button } from "react-bootstrap";
import {AiOutlineEdit,AiOutlineDelete,AiOutlineClose} from "react-icons/ai";
// import Form from "./form";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ADMIN_APIS_ROOM } from "../../../Constants";

const Room = () => {
    const navigate = useNavigate();
    const [room, setRoom] = useState([]);

    const [roomId, setRoomId] = useState();

    useEffect(()=>{
        getRooms();
    },[]);

    const getRooms = () => {
      fetch(ADMIN_APIS_ROOM+'index',{
        method:"get"
      }).then((response)=>{
        response.json().then((res)=>{
          if(res.status == true){
            setRoom(res.data);
          }
        });
      });
    }

    const deleteRoom = (id) => {
      fetch(ADMIN_APIS_ROOM+'delete',{
        method:"post",
        headers: {
            "Content-Type": "application/json"
          },
        body:JSON.stringify({'room_id':id})
      }).then((response)=>{
        response.json().then((res)=>{
          if(res.status == true){
            getRooms();
            // setHotelType(res.data);
          }
        });
      });
    }

    const moveToEdit = (roomId) => {
      navigate('edit',{state: { roomId:roomId }});
    }

    return (
        <>
        {/* <Button className="btn btn-success mb-2" onClick={(e)=>{gotoForm()}}>Add Location</Button> */}
        <Link to="create" className="btn btn-success mb-2">Add Room</Link>

        <table className="table table-bordered table-hover table-striped table-responsive-sm">
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Room Type</th>
                    <th>Hotel</th>
                    <th>Qty</th>
                    <th>Price</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
            {room && room.map((item,index) => (
                <tr key={index}>
                    <td>{index+1}</td>
                    <td>{item.roomtype.name}</td>
                    <td>{item.hotel.name}</td>
                    <td>{item.quantity}</td>
                    <td>{item.price}</td>
                    <td>{item.status}</td>
                    <td>
                        <AiOutlineEdit className="mr-3 text-primary" onClick={
                          ()=>{moveToEdit(item.id)}
                        }/>
                        <AiOutlineDelete className="text-danger" onClick={()=>{deleteRoom(item.id)}} />
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
        </>
    );
}

export default Room;