import React from "react";
import * as Components from "../../../Components";
import * as ReuseableComponents from "../../../ReuseableComponents";
import * as Hooks from "../../../Hooks";
import { Button } from "react-bootstrap";

const AvailableRooms = () => {
	// States...
	const [room_select_check, setRoomNoSelectCheck] = Hooks.useState(0);
	const [more_details_card_one, setMoreDetailsCardOne] =
		Hooks.useState(false);
	const [more_details_button_one, setMoreDetailsButtonOne] =
		Hooks.useState("More Details");
	const [more_details_card_two, setMoreDetailsCardTwo] =
		Hooks.useState(false);
	const [more_details_button_two, setMoreDetailsButtonTwo] =
		Hooks.useState("More Details");
	const [availability_card_one, setAvailabilityCardOne] =
		Hooks.useState(false);
	const [availability_select_open_one, setAvailabilitySelectOpenOne] =
		Hooks.useState(false);
	const [availability_select_one, setAvailabilitySelectOne] =
		Hooks.useState("Select Dates");
	const [availability_card_two, setAvailabilityCardTwo] =
		Hooks.useState(false);
	const [availability_select_open_two, setAvailabilitySelectOpenTwo] =
		Hooks.useState(false);
	const [availability_select_two, setAvailabilitySelectTwo] =
		Hooks.useState("Select Dates");
	const [room_no_select_open, setRoomNoSelectOpen] = Hooks.useState(false);
	const [room_no_select, setRoomNoSelect] = Hooks.useState("01");

	// Navigate...
	const navigate = Hooks.useNavigate();

	// On More Detail Button One...
	const onMoreDetailButtonOne = () => {
		if (more_details_card_one === false) {
			setMoreDetailsCardOne(true);
			setMoreDetailsButtonOne("Less Details");
		} else {
			setMoreDetailsCardOne(false);
			setMoreDetailsButtonOne("More Details");
		}
	};

	// On More Detail Button Two...
	const onMoreDetailButtonTwo = () => {
		if (more_details_card_two === false) {
			setMoreDetailsCardTwo(true);
			setMoreDetailsButtonTwo("Less Details");
		} else {
			setMoreDetailsCardTwo(false);
			setMoreDetailsButtonTwo("More Details");
		}
	};

	// On Select Dates One...
	const onSelectDatesOne = () => {
		setAvailabilitySelectOpenOne(!availability_select_open_one);

		if (!availability_select_open_one) {
			document
				.querySelector(".add_bottom_15.dates_one")
				.classList.add("open");
		} else {
			document
				.querySelector(".add_bottom_15.dates_one")
				.classList.remove("open");
		}
	};

	// On Select Dates Two...
	const onSelectDatesTwo = () => {
		setAvailabilitySelectOpenTwo(!availability_select_open_two);

		if (!availability_select_open_two) {
			document
				.querySelector(".add_bottom_15.dates_two")
				.classList.add("open");
		} else {
			document
				.querySelector(".add_bottom_15.dates_two")
				.classList.remove("open");
		}
	};

	// On Room Checked...
	const onRoomChecked = (event) => {
		const checked = event.target.checked;
		if (checked) {
			setRoomNoSelectCheck(1);
		} else {
			setRoomNoSelectCheck(0);
		}
	};

	// Open Room Select...
	const openRoomNoSelect = () => {
		setRoomNoSelectOpen(!room_no_select_open);

		if (!room_no_select_open) {
			document
				.querySelector(".add_bottom_15.rooms")
				.classList.add("open");
		} else {
			document
				.querySelector(".add_bottom_15.rooms")
				.classList.remove("open");
		}
	};

	// On Navigate Booking Page...
	const onNavigateBookingPage = () => {
		navigate("/hotels/booking")
	}

	return (
		<>
			{/* Table */}
			<table className="table">
				<thead>
					<tr>
						<th className="text-center">Room Types</th>
						<th className="text-center">Room Option</th>
						<th className="text-center">Select Room</th>
					</tr>
				</thead>
				<tbody>
					<tr className="available_rooms_row">
						<td>
							<div className="d-flex flex-column bg-light rounded	px-2 pt-2 pb-3 height_250">
								<img
									src="/backend/img/additional/hotels/hotel_1.jpg"
									alt="Hotel Image"
									className="card_image rounded"
								/>

								{/* Title */}
								<h6 className="font_weight_bold my-2 ms-1">
									Grand Plaza Apartments
								</h6>

								{/* Guests */}
								<ul className="mb-0 mt-2 d-flex justify-content-between">
									<li>
										<ReuseableComponents.ReactIcons.PERSON_MAN className="man_icon" />
										<span className="font_weight_medium ms-1">
											Adults
										</span>
										- 00
									</li>
									<li className="me-1">
										<ReuseableComponents.ReactIcons.CHILD className="child_icon" />
										<span className="font_weight_medium ms-1">
											Childs
										</span>
										- 00
									</li>
								</ul>
							</div>
						</td>

						<td>
							<div className="d-flex flex-column bg-light rounded	px-2 pt-2 pb-3 justify-content-center align-items-center height_250">
								<h3 className="price_detail mb-1">
									<strong>PKR 1,000</strong> Per Night
								</h3>

								<Button
									className="btn_1 btn_1_detail mt-3"
									onClick={onMoreDetailButtonOne}>
									{more_details_button_one}
								</Button>
								<Button
									className="btn_2 mt-3"
									onClick={() =>
										setAvailabilityCardOne(
											!availability_card_one,
										)
									}>
									Availability
								</Button>
							</div>
						</td>
						<td>
							<div className="d-flex flex-column align-items-center justify-content-center bg-light rounded px-2 pt-2 pb-3 text-center height_250">
								{/* Select Room */}
								<div
									className="form-group"
									style={{ width: "150px" }}>
									<label className="container_check">
										Select Room
										<input
											type="checkbox"
											name="select_room"
											onChange={onRoomChecked}
										/>
										<span className="checkmark check_box mt-1" />
									</label>
								</div>

								{/* NO. Of Rooms */}
								<div className="form-group">
									<label>NO. Of Rooms</label>

									<div
										className="custom-select-form"
										style={{ width: "150px" }}>
										<div
											className="nice-select wide add_bottom_15 rooms"
											onClick={() => openRoomNoSelect()}>
											<span className="current">
												{room_no_select}
											</span>
											<ul className="list">
												<li
													value="1"
													className="option"
													onClick={(e) =>
														setRoomNoSelect(
															"0" +
																e.target.value,
														)
													}>
													01
												</li>

												<li
													value="2"
													className="option"
													onClick={(e) =>
														setRoomNoSelect(
															"0" +
																e.target.value,
														)
													}>
													02
												</li>

												<li
													value="3"
													className="option"
													onClick={(e) =>
														setRoomNoSelect(
															"0" +
																e.target.value,
														)
													}>
													03
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</td>
					</tr>

					{/* More Details Card One */}
					{more_details_card_one && (
						<tr className="available_rooms_row">
							<Components.HotelAvailableRoomsDetail />
						</tr>
					)}

					{/* Availability Card One */}
					{availability_card_one && (
						<tr className="available_rooms_row">
							<td
								colSpan={3}
								className="w-100">
								<div className="container-fluid bg-light rounded p-3 height_100">
									{/* Select Dates */}
									<div className="form-group">
										<label>Select Dates</label>

										<div className="custom-select-form">
											<div
												className="nice-select wide add_bottom_15 dates_one"
												onClick={() =>
													onSelectDatesOne()
												}>
												<span className="current">
													{availability_select_one}
												</span>
												<ul className="list">
													<li
														className="option"
														onClick={() =>
															setAvailabilitySelectOne(
																"July 2023 - December 2023",
															)
														}>
														July 2023 - December
														2023
													</li>

													<li
														className="option"
														onClick={() =>
															setAvailabilitySelectOne(
																"January 2024 - June 2024",
															)
														}>
														January 2024 - June 2024
													</li>

													<li
														className="option"
														onClick={() =>
															setAvailabilitySelectOne(
																"July 2024 - December 2024",
															)
														}>
														July 2024 - December
														2024
													</li>

													<li
														className="option"
														onClick={() =>
															setAvailabilitySelectOne(
																"January 2025 - June 2025",
															)
														}>
														January 2025 - June 2025
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</td>
						</tr>
					)}

					<tr className="available_rooms_row">
						<td>
							<div className="d-flex flex-column bg-light rounded	px-2 pt-2 pb-3 height_250">
								<img
									src="/backend/img/additional/hotels/hotel_1.jpg"
									alt="Hotel Image"
									className="card_image rounded"
								/>

								{/* Title */}
								<h6 className="font_weight_bold my-2 ms-1">
									Grand Plaza Apartments
								</h6>

								{/* Guests */}
								<ul className="mb-0 mt-2 d-flex justify-content-between">
									<li>
										<ReuseableComponents.ReactIcons.PERSON_MAN className="man_icon" />
										<span className="font_weight_medium ms-1">
											Adults
										</span>
										- 00
									</li>
									<li className="me-1">
										<ReuseableComponents.ReactIcons.CHILD className="child_icon" />
										<span className="font_weight_medium ms-1">
											Childs
										</span>
										- 00
									</li>
								</ul>
							</div>
						</td>

						<td>
							<div className="d-flex flex-column bg-light rounded	px-2 pt-2 pb-3 justify-content-center align-items-center height_250">
								<h3 className="price_detail mb-1">
									<strong>PKR 1,000</strong> Per Night
								</h3>

								<Button
									className="btn_1 btn_1_detail mt-3"
									onClick={onMoreDetailButtonTwo}>
									{more_details_button_two}
								</Button>
								<Button
									className="btn_2 mt-3"
									onClick={() =>
										setAvailabilityCardTwo(
											!availability_card_two,
										)
									}>
									Availability
								</Button>
							</div>
						</td>
						<td>
							<div className="d-flex align-items-center justify-content-center bg-light rounded px-2 pt-2 pb-3 text-center height_250">
								<p className="empty_rooms_text mb-0">
									No Rooms Available! <br />
									All Rooms Are Booked
								</p>
							</div>
						</td>
					</tr>

					{/* More Details Card Two */}
					{more_details_card_two && (
						<tr className="available_rooms_row">
							<Components.HotelAvailableRoomsDetail />
						</tr>
					)}

					{/* Availability Card Two */}
					{availability_card_two && (
						<tr className="available_rooms_row">
							<td
								colSpan={3}
								className="w-100">
								<div className="container-fluid bg-light rounded p-3 height_100">
									{/* Select Dates */}
									<div className="form-group">
										<label>Select Dates</label>

										<div className="custom-select-form">
											<div
												className="nice-select wide add_bottom_15 dates_two"
												onClick={() =>
													onSelectDatesTwo()
												}>
												<span className="current">
													{availability_select_two}
												</span>
												<ul className="list">
													<li
														className="option"
														onClick={() =>
															setAvailabilitySelectTwo(
																"July 2023 - December 2023",
															)
														}>
														July 2023 - December
														2023
													</li>

													<li
														className="option"
														onClick={() =>
															setAvailabilitySelectTwo(
																"January 2024 - June 2024",
															)
														}>
														January 2024 - June 2024
													</li>

													<li
														className="option"
														onClick={() =>
															setAvailabilitySelectTwo(
																"July 2024 - December 2024",
															)
														}>
														July 2024 - December
														2024
													</li>

													<li
														className="option"
														onClick={() =>
															setAvailabilitySelectTwo(
																"January 2025 - June 2025",
															)
														}>
														January 2025 - June 2025
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</td>
						</tr>
					)}

					<tr className="available_rooms_row">
						<td
							colSpan={3}
							className="text-center">
							<Button
								className={`btn_1 book_now my-1 ${
									room_select_check === 0
										? "disabled_button"
										: ""
								}`}
								title="First Select Atleast One Room For Booking!"
								disabled={
									room_select_check === 0 ? true : false
								}
								onClick={onNavigateBookingPage}>
								Book Now
							</Button>
						</td>
					</tr>
				</tbody>
			</table>
		</>
	);
};

export default AvailableRooms;
