import { useState } from "react";
import CustomeTextFieldTranslate from "./CustomeTextFieldTranslate";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Translate = ({ data, setState }) => {

    const updatedLanguages = (code, objKey, value) => {
        setState(prevLanguage => ({ ...prevLanguage, [code]: { ...prevLanguage[code], [objKey]: value } }))
    }
    console.log('11    ',data);
    return (
        <>
            {
                Object.keys(data).map((key) => {
                    const item = data[key];
                    return (
                        <div className="mb-4">
                            <p>{item.lang_title}</p>

                            <CKEditor
                                editor={ClassicEditor}
                                data={item.trans_desc}
                                config={{ placeholder: "Room description" }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    updatedLanguages(item.trans_lang,'trans_desc',data);
                                }}
                            />

                            {/* <CustomeTextFieldTranslate value={item.description} code={key} objKey="description" changeState={updatedLanguages} placeholder="Room Description" /> */}

                        </div>
                    );
                })
            }

        </>
    );
}

export default Translate;