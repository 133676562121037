import { Select,FormControl,TextField,MenuItem,InputLabel } from "@mui/material";
import { Modal, Button, Row, Col } from "react-bootstrap";
import CustomeTextField from "./CustomeTextField";
import { useEffect, useState } from "react";
import { ADMIN_APIS_HOTELTYPE } from "../../../Constants";

const Form = ({closeModal,hotelTypeId,getHotelTypes}) => {
    const [name,setName] = useState("");
    const [status,setStatus] = useState("Yes");
    const [language,setLanguage] = useState({});
    const handleSubmit = () => {
        // console.log('18:::',name)
        let data = {
            name:name,
            status:status,
            languages:language
        }
        fetch(ADMIN_APIS_HOTELTYPE+'store',{
            method:"post",
            headers: {
                "Content-Type": "application/json"
              },
            body:JSON.stringify(data)
          }).then((response)=>{
            response.json().then((res)=>{
              if(res.status == true){
                closeModal();
                getHotelTypes();
              }
            });
          });
    }

    const handleSubmitUpdate = () => {
      // console.log('18:::',name)
      let data = {
          name:name,
          status:status,
          languages:language,
          hotel_type_id:hotelTypeId
      }
      fetch(ADMIN_APIS_HOTELTYPE+'update',{
          method:"post",
          headers: {
              "Content-Type": "application/json"
            },
          body:JSON.stringify(data)
        }).then((response)=>{
          response.json().then((res)=>{
            if(res.status == true){
              closeModal();
              getHotelTypes();
            }
          });
        });
  }

    const handleLanguage = (code,value) => {
        if(language.hasOwnProperty(code) && value == ''){
            const updatedLanguages = Object.keys(language).reduce((acc, key) => {
                if (key !== code) {
                  acc[key] = language[key];
                }
                return acc;
              }, {});
            setLanguage(updatedLanguages);
            return;
        }
        setLanguage(prevLanguage => ({ ...prevLanguage, [code]: {trans_lang:code,trans_name:value} }))
    }

    useEffect(()=>{
      if(hotelTypeId){
        fetch(ADMIN_APIS_HOTELTYPE+'edit',{
          method:"post",
          headers: {
            "Content-Type": "application/json"
          },
          body:JSON.stringify({hotel_type_id:hotelTypeId})
        }).then((response)=>{
          response.json().then((res)=>{
            if(res.status == true){
              setName(res.data.name);
              setStatus(res.data.status);
              let lanObj = {};
              res.data.hotel_type_translation.forEach(element => {
                lanObj[element.trans_lang] = element;
              });
              setLanguage(lanObj);
            }
          });
        });
      }
    },[]);

    // console.log('lllln;  ',language);
    // useEffect(()=>{
    //   console.log("edited alnuage    :  ",language.ar ? language.ar.trans_name : 'no data');
    // },[setLanguage]);
    return (
        <>
            <FormControl fullWidth className="mt-3">
                <TextField id="outlined-basic" label="Type Name" variant="outlined" onChange={(e)=>setName(e.target.value)} value={name} />
            </FormControl>
            {/* <CustomeTextField value={name} setState={setName} placeholder="Type Name" /> */}

            <FormControl fullWidth className="mt-3">
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    value={status}
                    onChange={(e)=>setStatus(e.target.value)}
                >
                    <MenuItem value={"Yes"}>Enable</MenuItem>
                    <MenuItem value={"No"}>Disable</MenuItem>
                </Select>
            </FormControl>

            <CustomeTextField value={language.ar ? language.ar.trans_name : ''} code="ar" setState={handleLanguage}  placeholder="Name in Arabic" />

            <CustomeTextField value={language.de ? language.de.trans_name : ''} code="de" setState={handleLanguage} placeholder="Name in German" />

            <CustomeTextField value={language.es ? language.es.trans_name : ''} code="es" setState={handleLanguage} placeholder="Name in Spanish" />

            <CustomeTextField value={language.fa ? language.fa.trans_name : ''} code="fa" setState={handleLanguage} placeholder="Name in Farsi" />

            <CustomeTextField value={language.fr ? language.fr.trans_name : ''} code="fr" setState={handleLanguage} placeholder="Name in French" />

            <CustomeTextField value={language.ru ? language.ru.trans_name : ''} code="ru" setState={handleLanguage} placeholder="Name in Russian" />

            <CustomeTextField value={language.tr ? language.tr.trans_name : ''} code="tr" setState={handleLanguage} placeholder="Name in Turkish" />

            <CustomeTextField value={language.vi ? language.vi.trans_name : ''} code="vi" setState={handleLanguage} placeholder="Name in Vietnamese" />

            <Button onClick={hotelTypeId ? handleSubmitUpdate : handleSubmit} className="mt-3" variant="primary">Submit</Button>
        </>
    );
}

export default Form;