import * as Hooks from "../../../Hooks";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "react-bootstrap";
// Styling
const CustomStyle = makeStyles({
    LogOutButton: {
      background: "#398E8B",
      color: "#fff",
      padding: "6px 30px",
      textTransform: "capitalize",
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        background: "#333",
        color: "#fff",
      },
    },
    SignOutButton: {
      background: "#398E8B",
      marginTop: "15px",
      color: "#fff",
      width: "50%",
      padding: "6px 30px",
      textTransform: "capitalize",
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        background: "#333",
        color: "#fff",
      },
    },
  
    CancelButton: {
      color: "#fff",
      marginTop: "5px",
      width: "50%",
      padding: "6px 30px",
      textTransform: "capitalize",
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        background: "#333",
        color: "#fff",
      },
    },
  });

const DashboardHeader = () => {
    //   StylingClasses
  const StylingClasses = CustomStyle();
    return (
        <div>
      <div className="container-fluid">
        <div className="nk-header-wrap py-2">
          <Hooks.Link
            to="/"
            target="_blank"
            className="btn btn-light text-light font-weight-bold btn-sm d-none d-md-inline-flex"
            style={{
              outline: "none",
              boxShadow: "none",
              background: "#398E8B",
            }}
          >
            <em className="icon ni ni-home" />
            <span>Go To Website</span>
          </Hooks.Link>

          <div className="ml-auto d-inline-flex">
            <Button
              variant="contained"
              className={StylingClasses.LogOutButton}
              // onClick={LogoutModal}
            >
              {/* <em className="icon ni ni-signout" /> */}
              {/* <span>Sign Out</span> */}
              Sign Out
            </Button>

            <div className="ml-3 mt-2">
              <div className="user-info d-none d-xl-block">
                <div className="user-name">Name</div>
                <div className="user-status user-status-unverified">
                  Email
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* .nk-header-wrap */}
      </div>
      {/* .container-fliud */}
    </div>
    );
}

export default DashboardHeader;