import React from "react";
import * as Components from "../../../Components";

const ContactUs = () => {
	// Site Title...
	document.title = "Contact Us || Travel Craze";

	return (
		<>
			{/* Top Banner */}
			<Components.TopBanner text="Contact Us" />

			{/* Contact Info */}
			<Components.ContactInfo />

			{/* Contact Us Main Content */}
			<Components.ContactUsMainContent />
		</>
	);
};

export default ContactUs;
