import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Grid } from '@mui/material';

const Facilities = ({amenities,selectedFacilities,handleCheckboxChange}) => {
    return (
        <>
        <Grid container>
            {
                amenities && amenities.map((item)=>{
                    return (<Grid item md={4}>
                        <FormControlLabel control={<Checkbox checked={selectedFacilities.includes(item.id)} value={item.id} onChange={handleCheckboxChange} />} label={item.name} />
                    </Grid>)
                })
            }
        </Grid>
        </>
    );
}

export default Facilities;