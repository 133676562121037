import React from "react";
import * as Components from "../../../Components";

const TourDetailContent = () => {
	return (
		<>
			<div className="container padding_30_30">
				<div className="row">
					{/* Main Content */}
					<div className="col-lg-9">
						{/* Description Content */}
						<section>
							<Components.TourDescriptionContent />
						</section>

						{/* Reviews Content */}
						<section>
							<Components.ReviewsContent />
						</section>
					</div>
				</div>
			</div>
		</>
	);
};

export default TourDetailContent;
