import React from "react";
import { Badge, Stack } from "react-bootstrap";

const CarDetailsPrivacyPoicy = () => {
	return (
		<>
			{/* Table */}
			<table className="table privacy_policy_table">
				<tbody>
					<tr>
						<td className="privacy_policy_table_heading">
							Privacy Policy
						</td>
						<td className="text_justify">
							Car hire, 24-hour front desk, Express
							check-in/check-out, Currency exchange, Ticket
							service, Luggage storage, Concierge service,
							Babysitting/child services, Laundry, VIP facilities
						</td>
					</tr>

					<tr>
						<td className="privacy_policy_table_heading">
							Payment Types
						</td>
						<td className="text_justify">
							<Stack
								direction="horizontal"
								gap={2}>
								<Badge className="privacy_policy_table_success_badge">
									Cash
								</Badge>
								<Badge className="privacy_policy_table_dark_badge">
									Pay On Arrival
								</Badge>
								<Badge className="privacy_policy_table_primary_badge">
									Credit Card
								</Badge>
								<Badge className="privacy_policy_table_warning_badge">
									Wire Transfer
								</Badge>
							</Stack>
						</td>
					</tr>
				</tbody>
			</table>
		</>
	);
};

export default CarDetailsPrivacyPoicy;
