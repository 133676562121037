import React from "react";
import * as Hooks from "../../Hooks";

const AuthLayout = () => {
	return (
		<>
			<Hooks.Outlet />
		</>
	);
};

export default AuthLayout;
