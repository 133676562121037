const ADMIN_APIS = "https://travelcrazebackend.xpertsgroup.net/api/admin/";

const ADMIN_APIS_LOCATION = "https://travelcrazebackend.xpertsgroup.net/api/admin/location/";

const ADMIN_APIS_HOTEL = "https://travelcrazebackend.xpertsgroup.net/api/admin/hotel/";

const ADMIN_APIS_HOTELTYPE = "https://travelcrazebackend.xpertsgroup.net/api/admin/hoteltype/";

const ADMIN_APIS_HOTELAMENITIES = "https://travelcrazebackend.xpertsgroup.net/api/admin/hotelamenities/";

const ADMIN_APIS_ROOMTYPE = "https://travelcrazebackend.xpertsgroup.net/api/admin/roomtype/";

const ADMIN_APIS_ROOMAMENITIES = "https://travelcrazebackend.xpertsgroup.net/api/admin/roomamenities/";

const ADMIN_APIS_ROOM = "https://travelcrazebackend.xpertsgroup.net/api/admin/room/";

export {
    ADMIN_APIS,
    ADMIN_APIS_LOCATION,
    ADMIN_APIS_HOTEL,
    ADMIN_APIS_HOTELTYPE,
    ADMIN_APIS_HOTELAMENITIES,
    ADMIN_APIS_ROOMTYPE,
    ADMIN_APIS_ROOMAMENITIES,
    ADMIN_APIS_ROOM
};