import React from "react";
import CONSTANTS from "../../../Data";
import * as ReuseableComponents from "../../../ReuseableComponents";

const HotelMainContent = () => {
	return (
		<>
			<div className="container-fluid margin_60_35">
				<div className="row">
					<aside
						className="col-lg-3"
						id="sidebar">
						{/* Hotel Filters */}
						<ReuseableComponents.HotelFilters />
					</aside>
					{/* /aside */}
					<div className="col-lg-9">
						<div className="isotope-wrapper">
							<div className="row">
								{/* Hotel Card */}
								<ReuseableComponents.HotelCard
									featured_hotels={
										CONSTANTS.FEATURED_HOTELS
											.featured_hotels
									}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default HotelMainContent;
