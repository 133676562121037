import React from "react";
import * as Hooks from "../../../Hooks";
import $ from "jquery";
import CONSTANTS from "../../../Data";

const HomeBannerFilter = () => {
	// States...
	const [categories_dropdown, setCategoriesDropdown] = Hooks.useState(false);
	const [categories_item, setCategoriesItem] = Hooks.useState("Hotels");
	const [tours_dropdown, setToursDropdown] = Hooks.useState(false);
	const [tours_item, setToursItem] = Hooks.useState("Tour Type");
	const [guests_dropdown, setGuestsDropdown] = Hooks.useState(false);
	const [adults_count, setAdultsCount] = Hooks.useState(0);
	const [childrens_count, setChildrensCount] = Hooks.useState(0);

	// Open Categories Dropdown...
	const openCategoriesDropdown = () => {
		setCategoriesDropdown(!categories_dropdown);

		if (!categories_dropdown) {
			document
				.querySelector(".panel-dropdown.categories")
				.classList.add("active");
		} else {
			document
				.querySelector(".panel-dropdown.categories")
				.classList.remove("active");
		}
	};

	// On Categories Item...
	const onCategoriesItem = (value) => {
		setCategoriesItem(value);
		setCategoriesDropdown(!categories_dropdown);

		if (!categories_dropdown) {
			document
				.querySelector(".panel-dropdown.categories")
				.classList.add("active");
		} else {
			document
				.querySelector(".panel-dropdown.categories")
				.classList.remove("active");
		}
	};

	// Open Tours Dropdown...
	const openToursDropdown = () => {
		setToursDropdown(!tours_dropdown);

		if (!tours_dropdown) {
			document
				.querySelector(".panel-dropdown.tours")
				.classList.add("active");
		} else {
			document
				.querySelector(".panel-dropdown.tours")
				.classList.remove("active");
		}
	};

	// On Tours Item...
	const onToursItem = (value) => {
		setToursItem(value);
		setToursDropdown(!tours_dropdown);

		if (!tours_dropdown) {
			document
				.querySelector(".panel-dropdown.tours")
				.classList.add("active");
		} else {
			document
				.querySelector(".panel-dropdown.tours")
				.classList.remove("active");
		}
	};

	// Open Guests Dropdown...
	const openGuestsDropdown = () => {
		setGuestsDropdown(!guests_dropdown);

		if (!guests_dropdown) {
			document
				.querySelector(".panel-dropdown.guests")
				.classList.add("active");
		} else {
			document
				.querySelector(".panel-dropdown.guests")
				.classList.remove("active");
		}
	};

	// On Adults Count Decrease...
	const onAdultsCountDecrease = () => {
		if (adults_count > 0) {
			setAdultsCount(adults_count - 1);
		} else {
			setAdultsCount(adults_count);
		}
	};

	// On Adults Count Increase...
	const onAdultsCountIncrease = () => {
		setAdultsCount(adults_count + 1);
	};

	// On Childrens Count Decrease...
	const onChildrensCountDecrease = () => {
		if (childrens_count > 0) {
			setChildrensCount(childrens_count - 1);
		} else {
			setChildrensCount(childrens_count);
		}
	};

	// On Childrens Count Increase...
	const onChildrensCountIncrease = () => {
		setChildrensCount(childrens_count + 1);
	};

	// useEffect...
	Hooks.useEffect(() => {
		$(function () {
			"use strict";
			// Hotels Dates...
			$('input[name="hotel_check_in_check_out"]').daterangepicker({
				autoUpdateInput: false,
				minDate: new Date(),
				locale: {
					cancelLabel: "Clear",
				},
			});
			$('input[name="hotel_check_in_check_out"]').on(
				"apply.daterangepicker",
				function (ev, picker) {
					$(this).val(
						picker.startDate.format("MMMM D, YYYY") +
							" > " +
							picker.endDate.format("MMMM D, YYYY"),
					);
				},
			);
			$('input[name="hotel_check_in_check_out"]').on(
				"cancel.daterangepicker",
				function (ev, picker) {
					$(this).val("");
				},
			);

			// Cars Dates...
			$('input[name="car_check_in_check_out"]').daterangepicker({
				autoUpdateInput: false,
				minDate: new Date(),
				locale: {
					cancelLabel: "Clear",
				},
			});
			$('input[name="car_check_in_check_out"]').on(
				"apply.daterangepicker",
				function (ev, picker) {
					$(this).val(
						picker.startDate.format("MMMM D, YYYY") +
							" > " +
							picker.endDate.format("MMMM D, YYYY"),
					);
				},
			);
			$('input[name="car_check_in_check_out"]').on(
				"cancel.daterangepicker",
				function (ev, picker) {
					$(this).val("");
				},
			);

			// Tours Date...
			$('input[name="tour_date"]').daterangepicker(
				{
					autoUpdateInput: true,
					parentEl: ".scroll-fix",
					singleDatePicker: true,
					autoApply: true,
					minDate: new Date(),
					showCustomRangeLabel: false,
					locale: {
						format: "MM-DD-YYYY",
					},
				},
				function (start, end, label) {
					console.log(
						"New date selected: " +
							start.format("YYYY-MM-DD") +
							" to " +
							end.format("DD-MM-YYYY") +
							" (predefined range: " +
							label +
							")",
					);
				},
			);
		});
	}, []);

	return (
		<>
			<form>
				<div className="row g-0 custom-search-input-2">
					{/* Categories */}
					<div className="col-lg-2">
						<div className="panel-dropdown categories">
							<Hooks.Link to="/">
								<div onClick={() => openCategoriesDropdown()}>
									{categories_item}
								</div>
							</Hooks.Link>
							<div className="panel-dropdown-content">
								{/* Hotels */}
								<div
									className={`qtyButtons ${
										categories_item === "Hotels"
											? "active_dropdown_item"
											: ""
									}`}>
									<label
										className="cursor_pointer"
										onClick={() =>
											onCategoriesItem("Hotels")
										}>
										Hotels
									</label>
								</div>

								{/* Cars */}
								<div
									className={`qtyButtons ${
										categories_item === "Cars"
											? "active_dropdown_item"
											: ""
									}`}>
									<label
										className="cursor_pointer"
										onClick={() =>
											onCategoriesItem("Cars")
										}>
										Cars
									</label>
								</div>

								{/* Tours */}
								<div
									className={`qtyButtons ${
										categories_item === "Tours"
											? "active_dropdown_item"
											: ""
									}`}>
									<label
										className="cursor_pointer"
										onClick={() =>
											onCategoriesItem("Tours")
										}>
										Tours
									</label>
								</div>
							</div>
						</div>
					</div>

					{categories_item === "Hotels" ? (
						<>
							{/* Search */}
							<div className="col-lg-3 mt-1 mt-md-0">
								<div className="form-group">
									<input
										className="form-control"
										type="text"
										placeholder="Search By Hotel Or City Name"
									/>
									<i className="icon_pin_alt" />
								</div>
							</div>

							{/* Check In Or Check Out */}
							<div className="col-lg-3">
								<div className="form-group">
									<input
										className="form-control"
										type="text"
										name="hotel_check_in_check_out"
										placeholder="Check In Or Check Out"
									/>
									<i className="icon_calendar" />
								</div>
							</div>

							{/* Guests */}
							<div className="col-lg-3">
								<div className="panel-dropdown guests">
									<Hooks.Link to="/">
										<div
											onClick={() =>
												openGuestsDropdown()
											}>
											Guests{" "}
											<span className="qtyTotal">
												{adults_count + childrens_count}
											</span>
										</div>
									</Hooks.Link>
									<div className="panel-dropdown-content">
										{/* Adults */}
										<div className="qtyButtons">
											<label>Adults</label>
											<div
												className="qtyDec"
												onClick={
													onAdultsCountDecrease
												}></div>
											<input
												type="text"
												name="qtyInput"
												value={adults_count}
												readOnly
											/>
											<div
												className="qtyInc"
												onClick={
													onAdultsCountIncrease
												}></div>
										</div>

										{/* Childrens */}
										<div className="qtyButtons">
											<label>Childrens</label>
											<div
												className="qtyDec"
												onClick={
													onChildrensCountDecrease
												}></div>
											<input
												type="text"
												name="qtyInput"
												value={childrens_count}
												readOnly
											/>
											<div
												className="qtyInc"
												onClick={
													onChildrensCountIncrease
												}></div>
										</div>
									</div>
								</div>
							</div>

							{/* Search Button */}
							<div className="col-lg-1">
								<input
									type="button"
									className="btn_search"
									value="Search"
								/>
							</div>
						</>
					) : categories_item === "Cars" ? (
						<>
							{/* Search */}
							<div className="col-lg-4 mt-1 mt-md-0">
								<div className="form-group">
									<input
										className="form-control"
										type="text"
										placeholder="Search By Car Or City Name"
									/>
									<i className="icon_pin_alt" />
								</div>
							</div>

							{/* Check In Or Check Out */}
							<div className="col-lg-4">
								<div className="form-group">
									<input
										className="form-control"
										type="text"
										name="car_check_in_check_out"
										placeholder="Check In Or Check Out"
									/>
									<i className="icon_calendar" />
								</div>
							</div>

							{/* Search Button */}
							<div className="col-lg-2">
								<input
									type="button"
									className="btn_search"
									value="Search"
								/>
							</div>
						</>
					) : (
						<>
							{/* Search */}
							<div className="col-lg-3 mt-1 mt-md-0">
								<div className="form-group">
									<input
										className="form-control"
										type="text"
										placeholder="Search By Listing Or City Name"
									/>
									<i className="icon_pin_alt" />
								</div>
							</div>

							{/* Date */}
							<div className="col-lg-2">
								<div className="form-group">
									<input
										className="form-control"
										type="text"
										name="tour_date"
										placeholder="Date"
									/>
									<i className="icon_calendar" />
								</div>
							</div>

							{/* Guests */}
							<div className="col-lg-2">
								<div className="panel-dropdown guests">
									<Hooks.Link to="/">
										<div
											onClick={() =>
												openGuestsDropdown()
											}>
											Guests{" "}
											<span className="qtyTotal">
												{adults_count + childrens_count}
											</span>
										</div>
									</Hooks.Link>
									<div className="panel-dropdown-content">
										{/* Adults */}
										<div className="qtyButtons">
											<label>Adults</label>
											<div
												className="qtyDec"
												onClick={
													onAdultsCountDecrease
												}></div>
											<input
												type="text"
												name="qtyInput"
												value={adults_count}
												readOnly
											/>
											<div
												className="qtyInc"
												onClick={
													onAdultsCountIncrease
												}></div>
										</div>
									</div>
								</div>
							</div>

							{/* Tour Type */}
							<div className="col-lg-2 mt-1 mt-md-0">
								<div className="panel-dropdown tours">
									<Hooks.Link to="/">
										<div
											onClick={() => openToursDropdown()}>
											{tours_item}
										</div>
									</Hooks.Link>
									<div className="panel-dropdown-content">
										{/* Tours Type */}
										{CONSTANTS.TOURS_TYPES.tours_type.map(
											(item, index) => (
												<div
													className={`qtyButtons ${
														tours_item ===
														item.value
															? "active_dropdown_item"
															: ""
													}`}
													key={index}>
													<label
														className="cursor_pointer"
														onClick={() =>
															onToursItem(
																item.value,
															)
														}>
														{item.label}
													</label>
												</div>
											),
										)}
									</div>
								</div>
							</div>

							{/* Search Button */}
							<div className="col-lg-1">
								<input
									type="button"
									className="btn_search"
									value="Search"
								/>
							</div>
						</>
					)}
				</div>
			</form>
		</>
	);
};

export default HomeBannerFilter;
