import React from "react";
import * as Components from "../../../Components";

const Tours = () => {
	// Site Title...
	document.title = "Tours || Travel Craze";

	return (
		<>
			{/* Top Banner */}
			<Components.TopBanner text="Tours" />

			{/* Tour Main Content */}
			<Components.TourMainContent />
		</>
	);
};

export default Tours;
