import React from "react";
import * as Hooks from "../../../Hooks";
import * as ReactIcons from "../../index"

const FooterTop = () => {
	return (
		<>
			<div className="row">
				<div className="col-lg-5 col-md-12 pe-5">
					<Hooks.Link to="/">
						<img
							src="/backend/logo.png"
							width={250}
							alt="Logo"
						/>
					</Hooks.Link>

					<div className="d-flex flex-row mt-4">
						<Hooks.Link
							to="/"
							className="btn_1 rounded">
							Supplier Registration
						</Hooks.Link>
						<Hooks.Link
							to="/"
							className="btn_1 rounded ms-2">
							Manage Property
						</Hooks.Link>
					</div>

					{/* <div className="follow_us">
						<ul>
							<h5 className="mb-2">Connected With Us</h5>
							<li>
								<Hooks.Link to="/">
								<ReactIcons.ReactIcons.FACE_BOOK_F className="footer_social_media_icon" />
								</Hooks.Link>
							</li>
							<li>
								<Hooks.Link to="/">
								<ReactIcons.ReactIcons.TWITTER className="footer_social_media_icon" />
								</Hooks.Link>
							</li>
							<li>
								<Hooks.Link to="/">
									<ReactIcons.ReactIcons.YOUTUBE className="footer_social_media_icon" />
								</Hooks.Link>
							</li>
							<li>
								<Hooks.Link to="/">
								<ReactIcons.ReactIcons.WHATSAPP className="footer_social_media_icon" />
								</Hooks.Link>
							</li>
							<li>
								<Hooks.Link to="/">
								<ReactIcons.ReactIcons.GOOGLE_PLUS_G className="footer_social_media_icon" />
								</Hooks.Link>
							</li>

							<li>
								<Hooks.Link to="/">
								<ReactIcons.ReactIcons.INSTAGRAM className="footer_social_media_icon" />
								</Hooks.Link>
							</li>
						</ul>
					</div> */}
				</div>

				{/* Company */}
				<div className="col-lg-2 col-md-6 ms-lg-auto">
					<h5>Company</h5>
					<ul className="links">
						<li>
							<Hooks.Link to="/">Contact Us</Hooks.Link>
						</li>
						<li>
							<Hooks.Link to="/">How To Book</Hooks.Link>
						</li>
						<li>
							<Hooks.Link to="/">Booking Tips</Hooks.Link>
						</li>
						<li>
							<Hooks.Link to="/">About Us</Hooks.Link>
						</li>
					</ul>
				</div>

				{/* Support */}
				<div className="col-lg-2 col-md-6 ms-lg-auto">
					<h5>Support</h5>
					<ul className="links">
						<li>
							<Hooks.Link to="/">Privacy Policy</Hooks.Link>
						</li>
						<li>
							<Hooks.Link to="/">Terms & Conditions</Hooks.Link>
						</li>
					</ul>
				</div>

				{/* News Letter */}
				<div className="col-lg-3 col-md-6">
					<h5>News Letter</h5>
					<ul className="contacts">
						<li>Subscribe To Get Our Latest Updates And Offers!</li>
					</ul>
					<div id="newsletter">
						<div id="message-newsletter" />
						<form
							method="post"
							action="#"
							name="newsletter_form"
							id="newsletter_form">
							<div className="form-group">
								<input
									type="email"
									name="email_newsletter"
									id="email_newsletter"
									className="form-control"
									placeholder="Your Email Address"
								/>
								<input
									type="button"
									defaultValue="Subscribe"
									id="submit-newsletter"
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default FooterTop;
