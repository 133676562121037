import React from "react";
import "../../Styles";
import "../../Scripts";
import * as Hooks from "../../Hooks";
import * as Components from "../../Components";

const FrontendLayout = () => {
	// States...
	const [window_scroll, setWindowScroll] = Hooks.useState(false);

	// UseEffect...
	Hooks.useEffect(() => {
		window.addEventListener("scroll", () => {
			setWindowScroll(window.scrollY > 50);
		});
	}, []);
	return (
		<>
			<div id="page">
				{/* Header */}
				<header
					className={
						window_scroll
							? "header menu_fixed sticky"
							: "header menu_fixed"
					}>
					<Components.Header />
				</header>

				{/* Main */}
				<main>
					<Hooks.Outlet />
				</main>

				{/* Footer */}
				<footer>
					<div className="container margin_60_0">
						<Components.Footer />
					</div>
				</footer>
			</div>
		</>
	);
};

export default FrontendLayout;
