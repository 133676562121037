import React from "react";
import * as ReuseableComponents from "../../../ReuseableComponents";

const HomeBanner = () => {
	return (
		<>
			<section className="hero_single version_2">
				<div className="wrapper">
					<div className="container">
						<h3>Hey, Where You Off To Next?</h3>

						{/* Home Banner Search */}
						<ReuseableComponents.HomeBannerFilter />
					</div>
				</div>
			</section>
		</>
	);
};

export default HomeBanner;
