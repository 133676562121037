import { useEffect,useState } from "react";
import { Modal,Button } from "react-bootstrap";
import {AiOutlineEdit,AiOutlineDelete,AiOutlineClose} from "react-icons/ai";
import Form from "./form";
import { ADMIN_APIS_HOTELTYPE } from "../../../Constants";

const HotelType = () => {
    const [AddModal, setAddModal] = useState(false);
    const [EditModal, setEditModal] = useState(false);
    const [hotelType, setHotelType] = useState([]);

    const [hotelTypeId, setHotelTypeId] = useState();
  
    const AddModalClose = () => setAddModal(false);
    const AddModalShow = () => setAddModal(true);

    const EditModalClose = () => setEditModal(false);
    const EditModalShow = (id) => {
      setHotelTypeId(id);
      setEditModal(true);
    };

    useEffect(()=>{
      getHotelTypes();
    },[]);

    const getHotelTypes = () => {
      fetch(ADMIN_APIS_HOTELTYPE+'index',{
        method:"get"
      }).then((response)=>{
        response.json().then((res)=>{
          if(res.status == true){
            setHotelType(res.data);
          }
        });
      });
    }

    const deleteType = (id) => {
      fetch(ADMIN_APIS_HOTELTYPE+'delete',{
        method:"post",
        headers: {
            "Content-Type": "application/json"
          },
        body:JSON.stringify({'hotel_type_id':id})
      }).then((response)=>{
        response.json().then((res)=>{
          if(res.status == true){
            getHotelTypes();
            // setHotelType(res.data);
          }
        });
      });
    }

    return (
        <>
        <Button className="btn btn-success mb-2" onClick={AddModalShow}>Add Hotel Type</Button>

        <table className="table table-bordered table-hover table-striped table-responsive-sm">
            <thead>
                <tr>
                    <th>id</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
            {hotelType && hotelType.map((item,index) => (
                <tr key={index}>
                    <td>{index+1}</td>
                    <td>{item.name}</td>
                    <td>{item.status}</td>
                    <td>
                        <AiOutlineEdit className="mr-3 text-primary" onClick={()=>EditModalShow(item.id)}/>
                        <AiOutlineDelete className="text-danger" onClick={()=>{deleteType(item.id)}} />
                    </td>
                </tr>
            ))}
            </tbody>
        </table>

        {AddModal === true ? (
        <Modal
          className="fade zoom"
          show={AddModalShow}
          onHide={AddModalClose}
          backdrop="static"
          size="md"
          scrollable={true}
        >
          <Modal.Header>
            <Modal.Title>Add Hotel Type</Modal.Title>
            <a href="#" className="close" onClick={AddModalClose}>
              <AiOutlineClose />
            </a>
          </Modal.Header>
          <Modal.Body>
            {/* Add Hotel Type */}
            <Form
              closeModal={AddModalClose}
              getHotelTypes={getHotelTypes}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={AddModalClose}>Cancel</Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}

      {/* edit modal */}

      {EditModal === true ? (
        <Modal
          className="fade zoom"
          show={EditModalShow}
          onHide={EditModalClose}
          backdrop="static"
          size="md"
          scrollable={true}
        >
          <Modal.Header>
            <Modal.Title>Edit Hotel Type</Modal.Title>
            <a href="#" className="close" onClick={EditModalClose}>
              <AiOutlineClose />
            </a>
          </Modal.Header>
          <Modal.Body>
            {/* Add Hotel Type */}
            <Form
              closeModal={EditModalClose}
              getHotelTypes={getHotelTypes}
              hotelTypeId={hotelTypeId}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={EditModalClose}>Cancel</Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}
        </>
    );
}

export default HotelType;