import React from "react";
import * as Hooks from "../../../Hooks";
import * as ReactIcons from "../../../ReuseableComponents";
import CONSTANTS from "../../../Data";
import { Button } from "react-bootstrap";

const HotelFilters = () => {
	// States...
	const [collapse_filters, setCollapseFilters] = Hooks.useState(true);
	const [hotel_amenities_count, setHotelAmenitiesCount] = Hooks.useState(4);

	// on Handle View More Hotel Amenities
	const onhandleViewMoreHotelAmenities = () => {
		setHotelAmenitiesCount((previousCount) => previousCount + 5);
	};

	// on Handle View Less Hotel Amenities
	const onhandleViewLessHotelAmenities = () => {
		setHotelAmenitiesCount((previousCount) => previousCount - 15);
	};

	// useEfferct...
	Hooks.useEffect(() => {
		if (collapse_filters) {
			document.querySelector(".collapse").classList.add("show");
			document
				.querySelector("#filters_col_bt")
				.classList.remove("collapsed");
		} else {
			document.querySelector(".collapse").classList.remove("show");
			document
				.querySelector("#filters_col_bt")
				.classList.add("collapsed");
		}
	}, [collapse_filters]);
	return (
		<>
			<div id="filters_col">
				<div>
					{/* Collapse Button */}
					<Hooks.Link
						to="#"
						data-bs-toggle="collapse"
						aria-expanded="false"
						aria-controls="collapseFilters"
						id="filters_col_bt"
						onClick={() => setCollapseFilters(!collapse_filters)}>
						Filter Search{" "}
					</Hooks.Link>

					{/* Filters */}
					<div
						className="collapse show"
						id="collapseFilters">
						{/* Star Grade */}
						<div className="filter_type">
							<h6>Star Grade</h6>
							<ul>
								<li>
									<label className="container_check">
										<span className="cat_star">
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon gray ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon gray ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon gray ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon gray ms-1" />
										</span>
										<input
											type="radio"
											name="star_rating"
										/>
										<span className="checkmark radio_filter mt-1" />
									</label>
								</li>

								<li>
									<label className="container_check">
										<span className="cat_star">
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon gray ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon gray ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon gray ms-1" />
										</span>
										<input
											type="radio"
											name="star_rating"
										/>
										<span className="checkmark radio_filter mt-1" />
									</label>
								</li>

								<li>
									<label className="container_check">
										<span className="cat_star">
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon gray ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon gray ms-1" />
										</span>
										<input
											type="radio"
											name="star_rating"
										/>
										<span className="checkmark radio_filter mt-1" />
									</label>
								</li>

								<li>
									<label className="container_check">
										<span className="cat_star">
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon gray ms-1" />
										</span>
										<input
											type="radio"
											name="star_rating"
										/>
										<span className="checkmark radio_filter mt-1" />
									</label>
								</li>

								<li>
									<label className="container_check">
										<span className="cat_star">
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow ms-1" />
											<ReactIcons.ReactIcons.FILL_STAR className="icon_star featured_card_star_icon yellow ms-1" />
										</span>
										<input
											type="radio"
											name="star_rating"
										/>
										<span className="checkmark radio_filter mt-1" />
									</label>
								</li>
							</ul>
						</div>

						{/* Property Types */}
						<div className="filter_type">
							<h6>Property Types</h6>
							<ul>
								{CONSTANTS.PROPERTY_TYPES.property_type.map(
									(item, index) => (
										<li key={index}>
											<label className="container_check">
												{item.label}
												<input type="checkbox" />
												<span className="checkmark check_box mt-1" />
											</label>
										</li>
									),
								)}
							</ul>
						</div>

						{/* Hotel Amenities */}
						<div className="filter_type">
							<h6>Hotel Amenities</h6>
							<ul>
								{CONSTANTS.HOTEL_AMENITIES.hotel_amenities
									.slice(0, hotel_amenities_count)
									.map((item, index) => (
										<li key={index}>
											<label className="container_check">
												{item.label}
												<input type="checkbox" />
												<span className="checkmark check_box mt-1" />
											</label>
										</li>
									))}
							</ul>

							{/* View More */}
							{hotel_amenities_count <
							CONSTANTS.HOTEL_AMENITIES.hotel_amenities.length ? (
								<Button
									className="btn_1 full-width rounded"
									onClick={onhandleViewMoreHotelAmenities}>
									View More
								</Button>
							) : (
								<Button
									className="btn_1 full-width rounded"
									onClick={onhandleViewLessHotelAmenities}>
									View Less
								</Button>
							)}
						</div>

						{/* Price Filter By */}
						<div className="filter_type">
							<h6>Price Filter By</h6>
							<ul>
								<li>
									<label className="container_check">
										High To Low
										<input
											type="radio"
											name="star_rating"
										/>
										<span className="checkmark radio_filter mt-1" />
									</label>
								</li>

								<li>
									<label className="container_check">
										Low To High
										<input
											type="radio"
											name="star_rating"
										/>
										<span className="checkmark radio_filter mt-1" />
									</label>
								</li>
							</ul>
						</div>

						{/* Search Button */}
						<div className="filter_type filter_button_bottom_wrapper">
							<Hooks.Link
								to="/"
								className="btn_1 full-width rounded">
								Search
							</Hooks.Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default HotelFilters;
