import { useState,useEffect } from "react";
import { Modal,Button } from "react-bootstrap";
import {AiOutlineEdit,AiOutlineDelete,AiOutlineClose} from "react-icons/ai";
import Form from "./form";
import { ADMIN_APIS_HOTELAMENITIES } from "../../../Constants";

const HotelAmenity = () => {
    const [AddModal, setAddModal] = useState(false);
    const [EditModal, setEditModal] = useState(false);
    const [hotelAmenity, setHotelAmenity] = useState([]);

    const [hotelAmenityId, setHotelAmenityId] = useState();
  
    const AddModalClose = () => setAddModal(false);
    const AddModalShow = () => setAddModal(true);

    const EditModalClose = () => setEditModal(false);
    const EditModalShow = (id) => {
      setHotelAmenityId(id);
      setEditModal(true);
    };

    useEffect(()=>{
      getHotelAmenities();
    },[]);

    const getHotelAmenities = () => {
      fetch(ADMIN_APIS_HOTELAMENITIES+'index',{
        method:"get"
      }).then((response)=>{
        response.json().then((res)=>{
          if(res.status == true){
            setHotelAmenity(res.data);
          }
        });
      });
    }

    const deleteAmenity = (id) => {
      fetch(ADMIN_APIS_HOTELAMENITIES+'delete',{
        method:"post",
        headers: {
            "Content-Type": "application/json"
          },
        body:JSON.stringify({'hotel_amenity_id':id})
      }).then((response)=>{
        response.json().then((res)=>{
          if(res.status == true){
            getHotelAmenities();
            // setHotelType(res.data);
          }
        });
      });
    }

    return (
        <>
        <Button className="btn btn-success mb-2" onClick={AddModalShow}>Add Hotel Amenity</Button>

        <table className="table table-bordered table-hover table-striped table-responsive-sm">
            <thead>
                <tr>
                    <th>id</th>
                    <th>icon</th>
                    <th>Name</th>
                    <th>Selected</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
            {hotelAmenity && hotelAmenity.map((item,index) => (
                <tr key={item.id}>
                    <td>{item.id}</td>
                    <td><img src={item.icon} heigh="100" width="100" /></td>
                    <td>{item.name}</td>
                    <td>{item.selected}</td>
                    <td>{item.status}</td>
                    <td>
                        <AiOutlineEdit className="mr-3 text-primary" onClick={()=>EditModalShow(item.id)}/>
                        <AiOutlineDelete className="text-danger" onClick={()=>{deleteAmenity(item.id)}} />
                    </td>
                </tr>
            ))}
            </tbody>
        </table>

        {AddModal === true ? (
            <Modal
              className="fade zoom"
              show={AddModalShow}
              onHide={AddModalClose}
              backdrop="static"
              size="md"
              scrollable={true}
            >
              <Modal.Header>
                <Modal.Title>Add Hotel Amenity</Modal.Title>
                <a href="#" className="close" onClick={AddModalClose}>
                  <AiOutlineClose />
                </a>
              </Modal.Header>
              <Modal.Body>
                {/* Add Hotel Amenity */}
                <Form
                  closeModal={AddModalClose}
                  getHotelAmenities={getHotelAmenities}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="light" onClick={AddModalClose}>Cancel</Button>
              </Modal.Footer>
            </Modal>
          ) : (
            ""
          )}


          {/* edit modal */}

          {EditModal === true ? (
            <Modal
              className="fade zoom"
              show={EditModalShow}
              onHide={EditModalClose}
              backdrop="static"
              size="md"
              scrollable={true}
            >
              <Modal.Header>
                <Modal.Title>Edit Hotel Amenity</Modal.Title>
                <a href="#" className="close" onClick={EditModalClose}>
                  <AiOutlineClose />
                </a>
              </Modal.Header>
              <Modal.Body>
                {/* Add Hotel Amenity */}
                <Form
                  closeModal={EditModalClose}
                  getHotelAmenities={getHotelAmenities}
                  hotelAmenityId={hotelAmenityId}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="light" onClick={EditModalClose}>Cancel</Button>
              </Modal.Footer>
            </Modal>
          ) : (
            ""
          )}
        </>
    );
}

export default HotelAmenity;