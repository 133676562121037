import React from "react";
import { Button } from "react-bootstrap";
import * as Hooks from "../../../../Hooks";
import * as ReuseableComponents from "../../../../ReuseableComponents";
import { ADMIN_APIS } from "../../../../Constants";
import { useNavigate  } from "react-router-dom";

const AdminLogin = () => {
	// Site Title...
	document.title = "Admin Login || Travel Craze";

	const [email,setEmail] = Hooks.useState("");
	const [password,setPassword] = Hooks.useState("");
	const navigate = useNavigate();

	// Use Effect...
	Hooks.useEffect(() => {
		document.body.id = "login_bg";

		return () => {
			document.body.id = "";
		};
	}, []);

	const loginAdmmin = () => {
		let data = {
			email:email,
			password:password
		};
        fetch(ADMIN_APIS + 'login', {
            method: 'post',
            body:JSON.stringify(data),
            headers:{
                'content-type':'application/json'
            }
        }).then((response) => {
            response.json().then((res) => {
				localStorage.setItem('user',JSON.stringify(res.data));
                if (res.status == true) {
                    navigate('/dashboard', { replace: true });
                }else{
					alert(res.message);
				}
            });
        });
	}
	return (
		<>
			<div id="login">
				<aside>
					<figure>
						<Hooks.Link to="#">
							<img
								src="/backend/logo.png"
								width="250"
								alt="Logo Image"
								className="logo_sticky"
							/>
						</Hooks.Link>
					</figure>
					<form autoComplete="off">
						{/* Email Address */}
						<div className="form-group">
							<label>Email Address</label>
							<input
								type="text"
								className="form-control"
								value={email}
								onChange={(e)=>setEmail(e.target.value)}
								placeholder="Enter Your Email Address"
								name="email_address"
							/>
						</div>

						{/* Password */}
						<div className="form-group">
							<label>Password</label>
							<input
								type="password"
								className="form-control"
								value={password}
								onChange={(e)=>setPassword(e.target.value)}
								placeholder="Enter Your Password"
								name="password"
							/>
						</div>

						{/* Button */}
						<Button onClick={loginAdmmin} className="btn_1 rounded full-width add_top_30">
							Login To Travel Craze!
						</Button>

						{/* Bottom Text */}
						<div className="text-center add_top_10">
							Don't have an acccount?
							<strong>
								<Hooks.Link
									to="/register"
									className="ms-2 footer_website_link">
									Register
								</Hooks.Link>
							</strong>
						</div>
					</form>

					<hr className="my-3" />

					{/* Copy Right */}
					<ReuseableComponents.FooterBottom />
				</aside>
			</div>
		</>
	);
};

export default AdminLogin;
