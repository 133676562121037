import { useState } from "react";
import CustomeTextField from "./CustomeTextField";

const Contact = ({contactData,setContactData}) => {
    // const [hotelEmail,setHotelEmail] = useState("");
    // const [hotelWebsite,setHotelWebsite] = useState("");
    // const [phone,setPhone] = useState("");

    const changeContactData = (key,value) => {
        setContactData({...contactData,[key]:value});
    }

    return (
        <>
            <CustomeTextField value={contactData.hotel_email} objKey='hotel_email' changeState={changeContactData} placeholder="Hotel's Email" />

            <CustomeTextField value={contactData.hotel_website} objKey='hotel_website' changeState={changeContactData} placeholder="Hotel's Website" />
            
            <CustomeTextField value={contactData.hotel_phone} objKey='hotel_phone' changeState={changeContactData} placeholder="Phone" />
        </>
    );
}

export default Contact;