import React from "react";
import * as ReactIcons from "../../../ReuseableComponents";

const ReviewsContent = () => {
	return (
		<>
			<div className="main_title_3 mt-3">
				<span>
					<em></em>
				</span>
				<h2>Reviews</h2>
			</div>

			<div className="reviews-container">
				<div className="review-box clearfix">
					<figure className="rev-thumb">
						<img
							src="/backend/img/avatar1.jpg"
							alt="true"
							className="rounded-circle"
						/>
					</figure>
					<div className="rev-content">
						<div className="rating mb-1">
							<ReactIcons.ReactIcons.FILL_STAR className="featured_card_star_icon yellow" />
							<ReactIcons.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
							<ReactIcons.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
							<ReactIcons.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
							<ReactIcons.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
						</div>
						<div className="rev-info">Admin - July 27, 2023:</div>
						<div className="rev-text">
							<p className="text_justify">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Nullam viverra rutrum aliquam.
								Sed posuere quam eget tellus dictum, et suscipit
								tellus pellentesque. Nullam elementum dui sit
								amet feugiat placerat. In aliquam dignissim
								convallis.
							</p>
						</div>
					</div>
				</div>

				<div className="review-box clearfix">
					<figure className="rev-thumb">
						<img
							src="/backend/img/avatar3.jpg"
							alt="true"
							className="rounded-circle"
						/>
					</figure>
					<div className="rev-content">
						<div className="rating mb-1">
							<ReactIcons.ReactIcons.FILL_STAR className="featured_card_star_icon yellow" />
							<ReactIcons.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
							<ReactIcons.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
							<ReactIcons.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
							<ReactIcons.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
						</div>
						<div className="rev-info">Admin - July 27, 2023:</div>
						<div className="rev-text">
							<p className="text_justify">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Nullam viverra rutrum aliquam.
								Sed posuere quam eget tellus dictum, et suscipit
								tellus pellentesque. Nullam elementum dui sit
								amet feugiat placerat. In aliquam dignissim
								convallis.
							</p>
						</div>
					</div>
				</div>

				<div className="review-box clearfix">
					<figure className="rev-thumb">
						<img
							src="/backend/img/avatar1.jpg"
							alt="true"
							className="rounded-circle"
						/>
					</figure>
					<div className="rev-content">
						<div className="rating mb-1">
							<ReactIcons.ReactIcons.FILL_STAR className="featured_card_star_icon yellow" />
							<ReactIcons.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
							<ReactIcons.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
							<ReactIcons.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
							<ReactIcons.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
						</div>
						<div className="rev-info">Admin - July 27, 2023:</div>
						<div className="rev-text">
							<p className="text_justify">
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit. Nullam viverra rutrum aliquam.
								Sed posuere quam eget tellus dictum, et suscipit
								tellus pellentesque. Nullam elementum dui sit
								amet feugiat placerat. In aliquam dignissim
								convallis.
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ReviewsContent;
