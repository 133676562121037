import React from "react";
import { Button, Modal } from "react-bootstrap";
import * as ReuseableComponents from "../../../ReuseableComponents"

const DefaultModal = (props) => {
	return (
		<>
			<Modal
				className="fade zoom default_modal"
				show={() => props.modalOpen(true)}
				onHide={() => props.modalClose(false)}
				backdrop="static"
				centered>
				{/* Header */}
				<Modal.Header className="border-0 m-0 justify-content-end modal_header">
					<ReuseableComponents.ReactIcons.OUTLINE_CLOSE
						className="modal_header_close_button"
						onClick={() => props.modalClose(false)}
					/>
				</Modal.Header>

				{/* Body */}
				<Modal.Body className="pb-0">
					<h3 className="heading">Your Account Are Not Login Yet!</h3>
					<p>Please, Login the account for add this in whish list.</p>
				</Modal.Body>

				{/* Footer */}
				<Modal.Footer className="justify-content-center border-0 pb-2 pt-0">
					<Button className="btn_1 w-50 rounded">Login</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default DefaultModal;
