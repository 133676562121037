// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import * as React from 'react';
// import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { makeStyles } from '@mui/styles';
import HotelType from '../../../ReuseableComponents/Backend/HotelType';
import RoomType from '../../../ReuseableComponents/Backend/RoomType';
import HotelAmenities from '../../../ReuseableComponents/Backend/HotelAmenities';
import RoomAmenities from '../../../ReuseableComponents/Backend/RoomAmenities';
import "../../../Styles/index";
import SideForm from './sideform';
import { Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import General from './general';
import Facilities from './facilities';
import MetaInfo from './metaInfo';
import Contact from './contact';
import Translate from './translate';
import Policy from './policy';
import { ADMIN_APIS_HOTEL } from "../../../Constants";
import { Button } from 'react-bootstrap';
import { useLocation,useNavigate  } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    customFlexContainer: {
        // Add your custom styling here
        justifyContent: 'space-around',
    },
}));

const Create = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const hotel_id = location.state ? location.state.hotelId : '';
    const [value, setValue] = React.useState('1');

    const classes = useStyles(); // Use the custom styles

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [generalData,setGeneralData] = React.useState({name:'',tag:'',longitude:'',latitude:'',mapAddress:''});
    const [hotelDescription,setHotelDescription] = React.useState('');
    const [metaData,setMetaData] = React.useState({meta_title:'',meta_keywords:'',meta_description:''});
    const [policyData,setPolicyData] = React.useState({check_in:'',check_out:'',policy_terms:''});
    const [contactData,setContactData] = React.useState({hotel_email:'',hotel_website:'',hotel_phone:''});
    const [sideformData,setSideformData] = React.useState({status:'',stars:'',hotel_location:'',hotel_type:'',featured:'',featured_from:'',featured_to:'',deposit:'',deposit_value:'',tax:'',tax_value:''});

    const [hotelTypes, setHotelTypes] = React.useState([]);
    const [locations, setLocations] = React.useState([]);
    const [amenities, setAmenities] = React.useState([]);
    const [languages, setLanguages] = React.useState({
        ar:{lang_title:'ARABIC',trans_lang:'ar',name:'',description:'',meta_title:'',meta_keywords:'',meta_description:'',policy_terms:''},
        ge:{lang_title:'GERMAN',trans_lang:'ge',name:'',description:'',meta_title:'',meta_keywords:'',meta_description:'',policy_terms:''},
        sp:{lang_title:'SPANISH',trans_lang:'sp',name:'',description:'',meta_title:'',meta_keywords:'',meta_description:'',policy_terms:''},
        fa:{lang_title:'FARSI',trans_lang:'fa',name:'',description:'',meta_title:'',meta_keywords:'',meta_description:'',policy_terms:''},
        fr:{lang_title:'FRENCH',trans_lang:'fr',name:'',description:'',meta_title:'',meta_keywords:'',meta_description:'',policy_terms:''},
        ru:{lang_title:'RUSSIAN',trans_lang:'ru',name:'',description:'',meta_title:'',meta_keywords:'',meta_description:'',policy_terms:''},
        tu:{lang_title:'TURKISH',trans_lang:'tu',name:'',description:'',meta_title:'',meta_keywords:'',meta_description:'',policy_terms:''},
        vi:{lang_title:'VIETNAMESE',trans_lang:'vi',name:'',description:'',meta_title:'',meta_keywords:'',meta_description:'',policy_terms:''}
    });

    const [selectedFacilities, setSelectedFacilities] = React.useState([]);
    const [selectAll, setSelectAll] = React.useState(false);

    React.useEffect(() => {
        fetch(ADMIN_APIS_HOTEL + 'create', {
            method: 'get'
        }).then((response) => {
            response.json().then((res) => {
                if (res.status == true) {
                    setHotelTypes(res.data.hotel_types);
                    setLocations(res.data.locations);
                    setAmenities(res.data.facilities);
                }
            });
        });

        if(hotel_id){
            fetch(ADMIN_APIS_HOTEL + 'edit', {
                method: 'post',
                body:JSON.stringify({hotel_id:hotel_id}),
                headers:{
                    'content-type':'application/json'
                }
            }).then((response) => {
                response.json().then((res) => {
                    if (res.status == true) {
                        const genData = res.data;
                        const translation = res.data.hotel_translation;
                        setGeneralData({name:genData.name,tag:genData.tag,longitude:genData.longitude,latitude:genData.latitude,mapAddress:genData.address_on_map});
                        setHotelDescription(genData.description);
                        setMetaData({meta_title:genData.meta_title,meta_keywords:genData.meta_keyword,meta_description:genData.meta_description});
                        setPolicyData({check_in:genData.check_in,check_out:genData.check_out,policy_terms:genData.policy_and_term});
                        setContactData({hotel_email:genData.hotel_email,hotel_website:genData.hotel_website,hotel_phone:genData.hotel_phone});
                        setSideformData({
                            status:genData.status,
                            stars:genData.stars,
                            hotel_location:genData.location_id,
                            hotel_type:genData.hotel_type_id,
                            featured:genData.featured,
                            featured_from:genData.featured_from,
                            featured_to:genData.featured_to,
                            deposit:genData.hotel_deposit,
                            deposit_value:genData.hotel_deposit_value,
                            tax:genData.hotel_tax,
                            tax_value:genData.hotel_tax_value
                        });
                        
                        let trans = {};
                        translation.forEach((obj,index) => {
                            const transLangMap = {
                                'ar': 'ARABIC',
                                'ge': 'GERMAN',
                                'sp': 'SPANISH',
                                'fa': 'FARSI',
                                'fr': 'FRENCH',
                                'ru': 'RUSSIAN',
                                'tu': 'TURKISH',
                                'vi': 'VIETNAMESE',
                            };
                            trans[obj.trans_lang] = {
                                lang_title: transLangMap[obj.trans_lang],
                                trans_lang:obj.trans_lang,
                                name:obj.trans_title,
                                description:obj.trans_desc,
                                meta_title:obj.metatitle,
                                meta_keywords:obj.metakeywords,
                                meta_description:obj.metadesc,
                                policy_terms:obj.trans_policy
                            }
                        });
                        setLanguages(trans);

                        const facilities = res.data.facility;
                        let fac_arr = [];
                        facilities.forEach((key,index) => {
                            fac_arr.push(key.pivot.facility_id);
                        })
                        setSelectedFacilities(fac_arr);
                    }
                });
            });
        }
    }, []);

    const handleCheckboxChange = (event) => {
        const facilityId = parseInt(event.target.value);
        if (event.target.checked) {
        setSelectedFacilities((prevSelected) => [...prevSelected, facilityId]);
        } else {
        setSelectedFacilities((prevSelected) =>
            prevSelected.filter((id) => id !== facilityId)
        );
        }
    };

    const handleSelectAllChange = () => {
        if (!selectAll) {
            setSelectedFacilities(amenities.map((facility) => facility.id));
        } else {
            setSelectedFacilities([]);
        }
        setSelectAll(!selectAll);
    };

    const submitGeneral = () => {
        let data = setBodyData();
        console.log('174 ;; ',data);
        // return;
        fetch(ADMIN_APIS_HOTEL + 'store', {
            method: 'post',
            body:JSON.stringify(data),
            headers:{
                'content-type':'application/json'
            }
        }).then((response) => {
            response.json().then((res) => {
                if (res.status == true) {
                    navigate(-1);
                }
            });
        });
    }

    const updateGeneral = () => {
        let data = setBodyData();
        data.hotel_id = hotel_id;
        fetch(ADMIN_APIS_HOTEL + 'update', {
            method: 'post',
            body:JSON.stringify(data),
            headers:{
                'content-type':'application/json'
            }
        }).then((response) => {
            response.json().then((res) => {
                if (res.status == true) {
                    navigate(-1);
                }
            });
        });
    }

    const setBodyData = () => {
        let data = {
            'name': generalData.name,
            'description':hotelDescription,
            'tag':generalData.tag,
            'address_on_map':generalData.mapAddress,
            'latitude':generalData.latitude,
            'longitude':generalData.longitude,
            'facilities':selectedFacilities,
            'meta_title':metaData.meta_title,
            'meta_keyword':metaData.meta_keywords,  
            'meta_description':metaData.meta_description,
            'check_in':policyData.check_in,
            'check_out':policyData.check_out,
            'policy_and_term':policyData.policy_terms,
            'hotel_email':contactData.hotel_email,
            'hotel_website':contactData.hotel_website,
            'hotel_phone':contactData.hotel_phone,
            'languages':languages,
            'status':sideformData.status,
            'stars':sideformData.stars,
            'featured':sideformData.featured,
            'featured_from':sideformData.featured_from,
            'featured_to':sideformData.featured_to,
            'hotel_type_id':sideformData.hotel_type,
            'location_id':sideformData.hotel_location,
            'hotel_deposit':sideformData.deposit,
            'hotel_deposit_value':sideformData.deposit_value,
            'hotel_tax':sideformData.tax,
            'hotel_tax_value':sideformData.tax_value
        }
        return data;
    }

    // console.log('102   ;  ',generalData);
    // console.log('102   ;  ',metaData);
    // console.log('102   ;  ',policyData);
    // console.log('102   ;  ',contactData);
    // console.log('102   ;  ',languages);
    // console.log('102   ;  ',sideformData);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item md={8}>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange}
                                    className={classes.customFlexContainer}
                                    aria-label="Settings Tabs">
                                    <Tab label="General" value="1" />
                                    <Tab label="Facilities" value="2" />
                                    <Tab label="Meta Info" value="3" />
                                    <Tab label="Policy" value="4" />
                                    <Tab label="Contact" value="5" />
                                    <Tab label="Translate" value="6" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <General hotelDescription={hotelDescription} setHotelDescription={setHotelDescription} generalData={generalData} setGeneralData={setGeneralData}/>
                            </TabPanel>
                            <TabPanel value="2">
                                <FormControlLabel control={<Checkbox checked={selectedFacilities.length == amenities.length} onChange={handleSelectAllChange} />} label="Select All" />
                                <br></br>
                                <Facilities amenities={amenities} selectedFacilities={selectedFacilities} handleCheckboxChange={handleCheckboxChange}/>
                            </TabPanel>
                            <TabPanel value="3">
                                <MetaInfo metaData={metaData} setMetaData={setMetaData} />
                            </TabPanel>
                            <TabPanel value="4">
                                <Policy policyData={policyData} setPolicyData={setPolicyData}/>
                            </TabPanel>
                            <TabPanel value="5">
                                <Contact contactData={contactData} setContactData={setContactData} />
                            </TabPanel>
                            <TabPanel value="6">
                                <Translate data={languages} setState={setLanguages}/>
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Grid>
                <Grid item md={4} elevation={3}>
                    <SideForm sideformData={sideformData} setSideformData={setSideformData} locations={locations} hotelTypes={hotelTypes} />
                </Grid>
                <Grid item md={8}>
                    <Button variant='primary' className='btn-block' onClick={hotel_id ? updateGeneral : submitGeneral}>{hotel_id ? 'Update' : 'Submit'}</Button>
                </Grid>
            </Grid>
        </>
    );
}

export default Create;