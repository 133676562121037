import React from "react";

const ContactInfo = () => {
	return (
		<>
			<div className="contact_info">
				<div className="container">
					<ul className="clearfix">
						<li>
							<i className="pe-7s-map-marker" />
							<h4>Address</h4>
							<span>Rahim Yar Khan, Paksitan</span>
						</li>
						<li>
							<i className="pe-7s-mail-open-file" />
							<h4>Email Address</h4>
							<span>info@xpertspot.com</span>
						</li>
						<li>
							<i className="pe-7s-phone" />
							<h4>Contact No</h4>
							<span>+1-234-56789</span>
						</li>
					</ul>
				</div>
			</div>
		</>
	);
};

export default ContactInfo;
