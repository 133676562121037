import { useState } from "react";
import CustomeTextField from "./CustomeTextField";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import './map.css';

const General = ({hotelDescription,setHotelDescription,generalData,setGeneralData}) => {
    // const [name, setName] = useState("");
    // const [tag, setTag] = useState("");
    // const [longitude, setLongitude] = useState("");
    // const [latitude, setLatitude] = useState("");
    // const [mapAddress, setMapAddress] = useState("");
    // const [description, setDescription] = useState("");
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAO5Lz3J7NeqpJY_3dX9W6LboylIrdh_2Y',
      });
    const center = { lat: 28.42192, lng: 70.29937 };

    const changeGeneralData = (key,value) => {
        setGeneralData({...generalData,[key]:value});
    }


    return (
        <>
            <CustomeTextField placeholder="Hotel Name" value={generalData.name} objKey='name' changeState={changeGeneralData} />

            <div className="mt-3">
                <CKEditor
                    editor={ClassicEditor}
                    data={hotelDescription}
                    config={{ placeholder: "Hotel description" }}
                    onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        // editor.isInitializing = true;
                        // editor.setData(generalData.description);
                        // console.log('Editor is ready to use!', generalData.description);
                    }}
                    onBlur={(event, editor) => {
                        // console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                        // console.log('Focus.', editor);
                    }}
                    onChange={(event, editor) => {
                        // if (!editor.isInitializing) {
                            const data = editor.getData();
                            setHotelDescription(data);
                        // }
                        // console.log({ event, editor, data });
                    }}
                />
            </div>

            <CustomeTextField placeholder="Hotel Tag" objKey='tag' value={generalData.tag} changeState={changeGeneralData} />

            <hr></hr>

            <CustomeTextField placeholder="Address on map" objKey='mapAddress' value={generalData.mapAddress} changeState={changeGeneralData} />

            <CustomeTextField placeholder="Longitude" objKey='longitude' value={generalData.longitude} changeState={changeGeneralData} />

            <CustomeTextField placeholder="Latitude" objKey='latitude' value={generalData.latitude} changeState={changeGeneralData} />
            {!isLoaded ? (
                <h1>Loading...</h1>
            ) : (
                <GoogleMap
                    mapContainerClassName="map-container"
                    // style={{height:'100px',width:'100%'}}
                    center={center}
                    zoom={10}
                >
                    <Marker position={center} />
                </GoogleMap>
            )}

        </>
    );
}

export default General;