import { useEffect, useState } from "react";
import { Select, FormControl, TextField, MenuItem, InputLabel, Grid, } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ADMIN_APIS_HOTEL } from "../../../Constants";
import ReuseableSelect from "./reusableSelect";
import CustomeTextField from "./CustomeTextField";
import dayjs from 'dayjs';

const SideForm = ({ sideformData, setSideformData, locations, hotelTypes }) => {

    const setLocationMenuItems = () => {
        return locations && locations.map((item) => {
            return <MenuItem value={item.id}>{item.location},{item.country}</MenuItem>
        })
    }

    const setHotelTypeMenuItems = () => {
        return hotelTypes && hotelTypes.map((item) => {
            return <MenuItem value={item.id}>{item.name}</MenuItem>
        })
    }

    const setStarMenuItems = () => {
        let ratting = [1, 2, 3, 4, 5];
        return ratting.map((item) => {
            return <MenuItem value={item}>{item}</MenuItem>
        })
    }

    const setStatusMenuItems = () => {
        let status = [{ 'value': 'Yes', 'label': 'Enable' }, { 'value': 'No', 'label': 'Disable' }];
        return status.map((item) => {
            return <MenuItem value={item.value}>{item.label}</MenuItem>
        })
    }

    const setFeaturedMenuItems = () => {
        let status = [{ 'value': 'Yes' }, { 'value': 'No' }];
        return status.map((item) => {
            return <MenuItem value={item.value}>{item.value}</MenuItem>
        });
    }

    const setDepositMenuItems = () => {
        let status = [{ 'value': 'percentage', 'label': 'Percentage' }, { 'value': 'fixed', 'label': 'Fixed' }];
        return status.map((item) => {
            return <MenuItem value={item.value}>{item.label}</MenuItem>
        });
    }

    const setTaxMenuItems = () => {
        let status = [{ 'value': 'percentage', 'label': 'Percentage' }, { 'value': 'fixed', 'label': 'Fixed' }];
        return status.map((item) => {
            return <MenuItem value={item.value}>{item.label}</MenuItem>
        });
    }

    const changeSideform = (key, value) => {
        setSideformData({ ...sideformData, [key]: value });
    }

    console.log('65 ;;',sideformData);

    return (
        <>
            <div>
                <ReuseableSelect label="Status" value={sideformData.status} objKey='status' changeState={changeSideform} data={setStatusMenuItems} />

                <ReuseableSelect label="Stars" value={sideformData.stars} objKey='stars' changeState={changeSideform} data={setStarMenuItems} />

                <ReuseableSelect label="Hotel Type" value={sideformData.hotel_type} objKey='hotel_type' changeState={changeSideform} data={setHotelTypeMenuItems} />

                <hr className="mb-0"></hr>

                <Grid container spacing={1}>
                    <Grid item md={12}>
                        <ReuseableSelect label="Featured" value={sideformData.featured} objKey='featured' changeState={changeSideform} data={setFeaturedMenuItems} />
                    </Grid>
                    <Grid item md={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker label="From" className="mt-3" value={dayjs(sideformData.featured_from)} onChange={(value)=> changeSideform('featured_from',dayjs(value).format('YYYY-MM-DD'))} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item md={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker label="To" className="mt-3" value={dayjs(sideformData.featured_to)} onChange={(value)=> changeSideform('featured_to',dayjs(value).format('YYYY-MM-DD'))} />
                        </LocalizationProvider>
                    </Grid>
                </Grid>

                <hr className="mb-0"></hr>

                <ReuseableSelect label="Location" value={sideformData.hotel_location} objKey='hotel_location' changeState={changeSideform} data={setLocationMenuItems} />

                <Grid container spacing={1}>
                    <Grid item md={5}>
                        <ReuseableSelect label="Deposit" value={sideformData.deposit} objKey='deposit' changeState={changeSideform} data={setDepositMenuItems} />
                    </Grid>
                    <Grid item md={7}>
                        <CustomeTextField type='number' value={sideformData.deposit_value} objKey='deposit_value' changeState={changeSideform} placeholder="Value" />
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item md={5}>
                        <ReuseableSelect label="Vat Tax" value={sideformData.tax} objKey='tax' changeState={changeSideform} data={setTaxMenuItems} />
                    </Grid>
                    <Grid item md={7}>
                        <CustomeTextField type='number' value={sideformData.tax_value} objKey='tax_value' changeState={changeSideform} placeholder="Value" />
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default SideForm;