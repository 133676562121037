import React from "react";
import * as Hooks from "../../../Hooks";
import { Badge, Button, Stack } from "react-bootstrap";
import CONSTANTS from "../../../Data";
import * as Components from "../../../Components";

const HotelDetailsPrivacyPoicy = () => {
	// States...
	const [write_review_card, setWriteReviewCard] = Hooks.useState(false);
	const [open_modal, setOpenModal] = Hooks.useState(false);

	const [clean_select_open, setCleanSelectOpen] = Hooks.useState(false);
	const [clean_select, setCleanSelect] = Hooks.useState("01");

	const [facility_select_open, setFacilitySelectOpen] = Hooks.useState(false);
	const [facility_select, setFacilitySelect] = Hooks.useState("01");

	const [comfort_select_open, setComfortSelectOpen] = Hooks.useState(false);
	const [comfort_select, setComfortSelect] = Hooks.useState("01");

	const [staff_select_open, setStaffSelectOpen] = Hooks.useState(false);
	const [staff_select, setStaffSelect] = Hooks.useState("01");

	const [location_select_open, setLocationSelectOpen] = Hooks.useState(false);
	const [location_select, setLocationSelect] = Hooks.useState("01");

	// On Add To Whish List...
	const onAddToWhishList = () => {
		return setOpenModal(true);
	};

	// Open Clean Select...
	const openCleanSelect = () => {
		setCleanSelectOpen(!clean_select_open);

		if (!clean_select_open) {
			document
				.querySelector(".add_bottom_15.clean")
				.classList.add("open");
		} else {
			document
				.querySelector(".add_bottom_15.clean")
				.classList.remove("open");
		}
	};

	// Open Facility Select...
	const openFacilitySelect = () => {
		setFacilitySelectOpen(!facility_select_open);

		if (!facility_select_open) {
			document
				.querySelector(".add_bottom_15.facilities")
				.classList.add("open");
		} else {
			document
				.querySelector(".add_bottom_15.facilities")
				.classList.remove("open");
		}
	};

	// Open Comfort Select...
	const openComfortSelect = () => {
		setComfortSelectOpen(!comfort_select_open);

		if (!comfort_select_open) {
			document
				.querySelector(".add_bottom_15.comforts")
				.classList.add("open");
		} else {
			document
				.querySelector(".add_bottom_15.comforts")
				.classList.remove("open");
		}
	};

	// Open Staff Select...
	const openStaffSelect = () => {
		setStaffSelectOpen(!staff_select_open);

		if (!staff_select_open) {
			document
				.querySelector(".add_bottom_15.staffs")
				.classList.add("open");
		} else {
			document
				.querySelector(".add_bottom_15.staffs")
				.classList.remove("open");
		}
	};

	// Open Location Select...
	const openLocationSelect = () => {
		setLocationSelectOpen(!location_select_open);

		if (!location_select_open) {
			document
				.querySelector(".add_bottom_15.locations")
				.classList.add("open");
		} else {
			document
				.querySelector(".add_bottom_15.locations")
				.classList.remove("open");
		}
	};

	return (
		<>
			{/* Table */}
			<table className="table privacy_policy_table">
				<tbody>
					<tr>
						<td className="privacy_policy_table_heading">
							Privacy Policy
						</td>
						<td className="text_justify">
							Room service, Car hire, 24-hour front desk, Express
							check-in/check-out, Currency exchange, Ticket
							service, Luggage storage, Concierge service,
							Babysitting/child services, Laundry, Dry cleaning,
							Ironing service, Meeting/banquet facilities,
							Business centre, Fax/photocopying, VIP room
							facilities
						</td>
					</tr>

					<tr>
						<td className="privacy_policy_table_heading">
							Check In
						</td>
						<td>12:00 AM</td>
					</tr>

					<tr>
						<td className="privacy_policy_table_heading">
							Check Out
						</td>
						<td>12:00 PM</td>
					</tr>

					<tr>
						<td className="privacy_policy_table_heading">
							Payment Types
						</td>
						<td className="text_justify">
							{/* Credit Card - Wire Transfer - Master / Visa Card - */}
							{/* Pay On Arrival  */}
							<Stack
								direction="horizontal"
								gap={2}>
								<Badge className="privacy_policy_table_primary_badge">
									Credit Card
								</Badge>
								<Badge className="privacy_policy_table_warning_badge">
									Wire Transfer
								</Badge>
								<Badge className="privacy_policy_table_success_badge">
									Master / Visa Card
								</Badge>
								<Badge className="privacy_policy_table_dark_badge">
									Pay On Arrival
								</Badge>
							</Stack>
						</td>
					</tr>
				</tbody>
			</table>

			{/* Buttons */}
			<div className="d-flex mt-3 justify-content-center">
				<Button
					className="btn_1 rounded"
					onClick={() => setWriteReviewCard(!write_review_card)}>
					Write Review
				</Button>
				<Button
					className="btn_2 rounded ms-2"
					onClick={onAddToWhishList}>
					Add To Whishlist
				</Button>
			</div>

			{/* Write Review Card */}
			{write_review_card === true && (
				<div className="box_detail mt-3">
					<div className="row">
						<div className="col-sm-12 col-md-6">
							<h2 className="font_weight_bold">
								Over All <span className="ms-2">1 / 10</span>
							</h2>

							<div className="row mt-3">
								{/* Clean */}
								<div className="col-sm-12 col-md-6">
									<div className="form-group">
										<label>Clean</label>

										<div className="custom-select-form">
											<div
												className="nice-select wide add_bottom_15 clean"
												tabIndex={0}
												onClick={() =>
													openCleanSelect()
												}>
												<span className="current">
													{clean_select}
												</span>
												<ul className="list">
													{CONSTANTS.COUNT_ARRAY.count_array.map(
														(item, index) => (
															<li
																key={index}
																defaultValue={
																	item.value
																}
																className="option"
																onClick={() =>
																	setCleanSelect(
																		item.value,
																	)
																}>
																{item.label}
															</li>
														),
													)}
												</ul>
											</div>
										</div>
									</div>
								</div>
								{/* Facilities */}
								<div className="col-sm-12 col-md-6 mt-3 mt-md-0">
									<div className="form-group">
										<label>Facilities</label>

										<div className="custom-select-form">
											<div
												className="nice-select wide add_bottom_15 facilities"
												tabIndex={0}
												onClick={() =>
													openFacilitySelect()
												}>
												<span className="current">
													{facility_select}
												</span>
												<ul className="list">
													{CONSTANTS.COUNT_ARRAY.count_array.map(
														(item, index) => (
															<li
																key={index}
																defaultValue={
																	item.value
																}
																className="option"
																onClick={() =>
																	setFacilitySelect(
																		item.value,
																	)
																}>
																{item.label}
															</li>
														),
													)}
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="row mt-3">
								{/* Comfort */}
								<div className="col-sm-12 col-md-6">
									<div className="form-group">
										<label>Comfort</label>

										<div className="custom-select-form">
											<div
												className="nice-select wide add_bottom_15 comforts"
												tabIndex={0}
												onClick={() =>
													openComfortSelect()
												}>
												<span className="current">
													{comfort_select}
												</span>
												<ul className="list">
													{CONSTANTS.COUNT_ARRAY.count_array.map(
														(item, index) => (
															<li
																key={index}
																defaultValue={
																	item.value
																}
																className="option"
																onClick={() =>
																	setComfortSelect(
																		item.value,
																	)
																}>
																{item.label}
															</li>
														),
													)}
												</ul>
											</div>
										</div>
									</div>
								</div>

								{/* Staff */}
								<div className="col-sm-12 col-md-6 mt-3 mt-md-0">
									<div className="form-group">
										<label>Staff</label>

										<div className="custom-select-form">
											<div
												className="nice-select wide add_bottom_15 staffs"
												tabIndex={0}
												onClick={() =>
													openStaffSelect()
												}>
												<span className="current">
													{staff_select}
												</span>
												<ul className="list">
													{CONSTANTS.COUNT_ARRAY.count_array.map(
														(item, index) => (
															<li
																key={index}
																defaultValue={
																	item.value
																}
																className="option"
																onClick={() =>
																	setStaffSelect(
																		item.value,
																	)
																}>
																{item.label}
															</li>
														),
													)}
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* Location */}
							<div className="row mt-3">
								<div className="col-12">
									<div className="form-group">
										<label>Location</label>

										<div className="custom-select-form">
											<div
												className="nice-select wide add_bottom_15 locations"
												tabIndex={0}
												onClick={() =>
													openLocationSelect()
												}>
												<span className="current">
													{location_select}
												</span>
												<ul className="list">
													{CONSTANTS.COUNT_ARRAY.count_array.map(
														(item, index) => (
															<li
																key={index}
																defaultValue={
																	item.value
																}
																className="option"
																onClick={() =>
																	setLocationSelect(
																		item.value,
																	)
																}>
																{item.label}
															</li>
														),
													)}
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Form */}
						<div className="col-sm-12 col-md-6 mt-3 mt-md-0">
							<div className="row">
								{/* Your Name */}
								<div className="col-sm-12">
									<div className="form-group">
										<input
											type="text"
											id="name"
											name="name"
											className="form-control"
											placeholder="Your Name"
										/>
									</div>
								</div>
							</div>

							<div className="row">
								{/* Your Email Address */}
								<div className="col-sm-12">
									<div className="form-group">
										<input
											type="text"
											id="email_address"
											name="email_address"
											className="form-control"
											placeholder="Your Email Address"
										/>
									</div>
								</div>
							</div>

							<div className="row">
								{/* Your Review */}
								<div className="col-sm-12">
									<div className="form-group">
										<textarea
											className="form-control text_arae"
											id="review"
											name="review"
											placeholder="Your Review"></textarea>
									</div>
								</div>
							</div>

							<small className="text-danger">
								<strong>Please Note: </strong> Once review added
								then can't be deleted or updated!
							</small>

							<Button className="btn_2 rounded full-width mt-2">
								Submit
							</Button>
						</div>
					</div>
				</div>
			)}

			{/* Open Modal */}
			{open_modal && (
				<Components.DefaultModal
					modalOpen={() => setOpenModal(true)}
					modalClose={() => setOpenModal(false)}
				/>
			)}
		</>
	);
};

export default HotelDetailsPrivacyPoicy;
