import React from "react";
import * as ReuseableComponents from "../../../ReuseableComponents";
import * as Hooks from "../../../Hooks";

const HotelDescriptionContent = () => {
	return (
		<>
			{/* Image */}
			<img
				src="/backend/img/additional/hotels/hotel_1.jpg"
				alt="Hotel_Image"
				className="detail_image"
			/>

			{/* Social Icons */}
			<ul className="share-buttons text_left">
				<li>
					<Hooks.Link
						to="/"
						className="fb-share">
						<ReuseableComponents.ReactIcons.FACE_BOOK_F /> Share
					</Hooks.Link>
				</li>
				<li>
					<Hooks.Link
						to="/"
						className="twitter-share"
						href="/">
						<ReuseableComponents.ReactIcons.TWITTER /> Tweet
					</Hooks.Link>
				</li>
				<li>
					<Hooks.Link
						to="/"
						className="gplus-share"
						href="/">
						<ReuseableComponents.ReactIcons.PINTEREST_P /> Pin
					</Hooks.Link>
				</li>
				<li>
					<Hooks.Link
						to="/"
						className="mail_share"
						href="/">
						<ReuseableComponents.ReactIcons.ENVELOPE_FILL /> Mail
					</Hooks.Link>
				</li>
				<li>
					<Hooks.Link
						to="/"
						className="whatsapp_share"
						href="/">
						<ReuseableComponents.ReactIcons.WHATSAPP /> Whatsapp
					</Hooks.Link>
				</li>
				<li>
					<Hooks.Link
						to="/"
						className="linkedin_share"
						href="/">
						<ReuseableComponents.ReactIcons.LINKEDIN_OPTION />{" "}
						Linkedin
					</Hooks.Link>
				</li>
			</ul>

			{/* Title */}
			<h2 className="font_weight_bold mb-0">Grand Plaza Apartments</h2>

			{/* Rating */}
			<div className="cat_star mb-2">
				<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon yellow" />
				<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
				<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
				<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
				<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
			</div>

			{/* Price */}
			<span className="price_detail mb-1">
				<strong>PKR 1,000</strong> Per Night
			</span>

			{/* Location */}
			<p>
				<ReuseableComponents.ReactIcons.LOCATION_ON className="featured_card_location_icon me-1" />
				London
			</p>

			{/* Over View */}
			<div className="main_title_3 mt-3">
				<span>
					<em></em>
				</span>
				<h2>Over View</h2>
			</div>
			<p className="text_justify">
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
				viverra rutrum aliquam. Sed posuere quam eget tellus dictum, et
				suscipit tellus pellentesque. Nullam elementum dui sit amet
				feugiat placerat. In aliquam dignissim convallis. Sed vestibulum
				erat turpis, nec finibus nisl ullamcorper semper. Maecenas ante
				odio, fermentum at nunc sit amet, posuere consectetur nibh.
				Donec vel nibh posuere, semper augue at, eleifend quam. Nulla at
				consequat ante. Praesent fermentum leo sed scelerisque tempus.
				Duis arcu nunc, accumsan at hendrerit sed, euismod nec sem.
				Donec vel pellentesque dolor. Mauris finibus pellentesque
				lobortis. Aenean semper nisi non congue cursus. Pellentesque
				fringilla quam sodales, mattis lorem quis, elementum nulla.
				Integer at facilisis libero. Aenean venenatis ac lectus eu
				bibendum. Praesent enim urna, consequat ac ultricies at, semper
				et quam. Vestibulum non urna tristique, posuere leo in, gravida
				purus. Phasellus pretium ac justo vel interdum. Vestibulum ante
				ipsum primis in faucibus orci luctus et ultrices.
			</p>

			{/* Available Rooms */}
			<div
				className="main_title_3 mt-3"
				id="available_rooms">
				<span>
					<em></em>
				</span>
				<h2>Available Rooms</h2>
			</div>
			<ReuseableComponents.AvailableRooms />

			{/* Find Us Here */}
			<div className="main_title_3 mt-3">
				<span>
					<em></em>
				</span>
				<h2>Find Us Here</h2>
			</div>
			<ReuseableComponents.Map />

			{/* Privacy Policy */}
			<div className="main_title_3 mt-3">
				<span>
					<em></em>
				</span>
				<h2>Privacy Policy</h2>
			</div>
			<ReuseableComponents.HotelDetailsPrivacyPoicy />
		</>
	);
};

export default HotelDescriptionContent;
