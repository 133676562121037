import React from "react";
import * as ReuseableComponents from "../../../ReuseableComponents";
import * as Hooks from "../../../Hooks";
import { Alert, Button, FormCheck, Tab, Tabs } from "react-bootstrap";

const CarBookingMainContent = () => {
	// States...
	const [country_select_open, setCountrySelectOpen] = Hooks.useState(false);
	const [countries_select, setCountrySelect] =
		Hooks.useState("Select Country");

		// Navigate...
		const navigate = Hooks.useNavigate();

	// Open Country Select...
	const openCountrySelect = () => {
		setCountrySelectOpen(!country_select_open);

		if (!country_select_open) {
			document
				.querySelector(".add_bottom_15.countries")
				.classList.add("open");
		} else {
			document
				.querySelector(".add_bottom_15.countries")
				.classList.remove("open");
		}
	};

	// On Navigate Invoice Page...
	const onNavigateInvoicePage = () => {
		navigate("/cars/invoice");
	}

	return (
		<>
			<div className="bg_color_1">
				<div className="container padding_30_30">
					{/* Success Alert */}
					<Alert
						variant="success"
						className="border-0 py-4 px-4 mb-5">
						<h5 className="font_weight_bold">
							Please read the terms before proceed
						</h5>
						<p className="mb-0">
							Enjoy the online booking features including last
							minute offer, best prices, booking history, and much
							more from your dedicated account panel to manage
							your services. Also, try our mobile apps to reserve
							from anywhere, anytime.
						</p>
					</Alert>

					<div className="row">
						{/* ASide */}
						<aside
							className="col-lg-4"
							id="sidebar">
							<div className="box_detail mb-0">
								<div className="row">
									{/* Image */}
									<div className="col-sm-12 col-md-4">
										<img
											src="/backend/img/additional/cars/car_1.jpg"
											alt="Car Image"
											className="invoice_image"
										/>
									</div>

									{/* Content */}
									<div className="col-sm-12 col-md-8 mt-3 mt-md-0">
										{/*Title  */}
										<h3 className="font_weight_bold my-0 fs_15">
										Opel Corsa 2013
										</h3>

										{/* Rating */}
										<div className="cat_star">
											<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon yellow" />
											<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
											<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
											<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
											<ReuseableComponents.ReactIcons.FILL_STAR className="featured_card_star_icon gray" />
										</div>

										{/* Location */}
										<p className="mb-0">
											<ReuseableComponents.ReactIcons.LOCATION_ON className="featured_card_location_icon me-1" />
											Petra
										</p>
									</div>
								</div>
								<hr className="my-3" />

								{/* Your Details */}
								<div id="total_cart">Your Details</div>
								<ul className="cart_details border-0">
									<li>
										Pick Up Location <span>Islamabad</span>
									</li>
									<li>
										Pick Up Date<span>August 04, 2023</span>
									</li>
									<li>
										Pick Up Time<span>10:00 AM</span>
									</li>
									<li>
										Drop Off Location<span>Karachi</span>
									</li>
									<li>
										Sub Total<span>PKR 100</span>
									</li>
								</ul>

								{/* Extras */}
								<div id="total_cart">Extras</div>
								<ul className="cart_details mb-0">
									<li>
										Tax / VAT<span>PKR 00</span>
									</li>
								</ul>

								<h6 className="mt-3">
									Total Amount{" "}
									<span className="float-end">PKR 100</span>
								</h6>
							</div>
						</aside>

						{/* Main Content */}
						<div className="col-lg-8">
							{/* Personal Details */}
							<div className="main_title_3">
								<span>
									<em></em>
								</span>
								<h2>Personal Details</h2>
							</div>

							{/* Tabs */}
							<Tabs
								defaultActiveKey="book_as_a_guest"
								id="hotel_booking_tabs">
								{/* Book As A Guest */}
								<Tab
									eventKey="book_as_a_guest"
									title="Book As A Guest">
									{/* Content */}
									<div className="row">
										{/* First Name */}
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<input
													type="text"
													id="first_name"
													name="first_name"
													className="form-control"
													placeholder="First Name"
												/>
											</div>
										</div>

										{/* Last Name */}
										<div className="col-sm-12 col-md-6 mt-3 mt-md-0">
											<div className="form-group">
												<input
													type="text"
													id="last_name"
													name="last_name"
													className="form-control"
													placeholder="Last Name"
												/>
											</div>
										</div>
									</div>

									<div className="row">
										{/* Email Address */}
										<div className="col-sm-12 col-md-6">
											<div className="form-group">
												<input
													type="text"
													id="email_address"
													name="email_address"
													className="form-control"
													placeholder="Email Address"
												/>
											</div>
										</div>

										{/* Confirm Email Address */}
										<div className="col-sm-12 col-md-6 mt-3 mt-md-0">
											<div className="form-group">
												<input
													type="text"
													id="confirm_email_address"
													name="confirm_email_address"
													className="form-control"
													placeholder="Confirm Email Address"
												/>
											</div>
										</div>
									</div>

									{/* Contact No */}
									<div className="row">
										<div className="col-sm-12">
											<div className="form-group">
												<input
													type="number"
													id="contact_no"
													name="contact_no"
													className="form-control"
													placeholder="Contact No"
												/>
											</div>
										</div>
									</div>

									{/* Address */}
									<div className="row">
										<div className="col-sm-12">
											<div className="form-group">
												<input
													type="text"
													id="address"
													name="address"
													className="form-control"
													placeholder="Address"
												/>
											</div>
										</div>
									</div>

									{/* Country */}
									<div className="row">
										<div className="col-sm-12">
											<div className="form-group">
												<div className="custom-select-form">
													<div
														className="nice-select wide add_bottom_15 countries"
														onClick={() =>
															openCountrySelect()
														}>
														<span className="current">
															{countries_select}
														</span>
														<ul className="list">
															<li
																className="option"
																onClick={() =>
																	setCountrySelect(
																		"Select Country",
																	)
																}>
																Select Country
															</li>

															<li
																className="option"
																onClick={() =>
																	setCountrySelect(
																		"United States Arab Emirates",
																	)
																}>
																United States
																Arab Emirates
															</li>

															<li
																className="option"
																onClick={() =>
																	setCountrySelect(
																		"Pakistan",
																	)
																}>
																Pakistan
															</li>

															<li
																className="option"
																onClick={() =>
																	setCountrySelect(
																		"India",
																	)
																}>
																India
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>

									{/* Additional Notes */}
									<div className="row">
										<div className="col-sm-12">
											<div className="form-group">
												<textarea
													className="form-control text_arae"
													id="notes"
													name="notes"
													placeholder="You can enter any additional notes or information you want included with your order here..."></textarea>
											</div>
										</div>
									</div>
								</Tab>

								{/* Sign In */}
								<Tab
									eventKey="sign_in"
									title="Sign In">
									{/* Email Address */}
									<div className="row">
										<div className="col-sm-12">
											<div className="form-group">
												<input
													type="text"
													id="email_address"
													name="email_address"
													className="form-control"
													placeholder="Email Address"
												/>
											</div>
										</div>
									</div>

									{/* Password */}
									<div className="row">
										<div className="col-sm-12">
											<div className="form-group">
												<input
													type="password"
													id="password"
													name="password"
													className="form-control"
													placeholder="Password"
												/>
											</div>
										</div>
									</div>

									{/* Additional Notes */}
									<div className="row">
										<div className="col-sm-12">
											<div className="form-group">
												<textarea
													className="form-control text_arae"
													id="notes"
													name="notes"
													placeholder="You can enter any additional notes or information you want included with your order here..."></textarea>
											</div>
										</div>
									</div>
								</Tab>
							</Tabs>

							{/* Extras */}
							<div className="main_title_3 mt-3 mb-3">
								<span>
									<em></em>
								</span>
								<h2>Extras</h2>
							</div>
							{/* Table */}
							<table className="table">
								<thead>
									<tr>
										<th className="text-left">Image</th>
										<th className="text-left">Item</th>
										<th className="text-left">Price</th>
										<th className="text-left">Order</th>
									</tr>
								</thead>
								<tbody>
									<tr className="available_rooms_row">
										<td>
											<img
												src="/backend/img/additional/1.jpg"
												alt="one"
												className="rounded"
												width={100}
											/>
										</td>
										<td className="vertical_align_middle">
											Flower bouquet in Room
										</td>
										<td className="vertical_align_middle">
											PKR 30
										</td>
										<td className="vertical_align_middle">
											<FormCheck
												type="switch"
												className="switch_toggle"
											/>
										</td>
									</tr>

									<tr className="available_rooms_row">
										<td>
											<img
												src="/backend/img/additional/2.jpg"
												alt="one"
												className="rounded"
												width={100}
											/>
										</td>
										<td className="vertical_align_middle">
											Champagne
										</td>
										<td className="vertical_align_middle">
											PKR 150
										</td>
										<td className="vertical_align_middle">
											<FormCheck
												type="switch"
												className="switch_toggle"
											/>
										</td>
									</tr>

									<tr className="available_rooms_row">
										<td>
											<img
												src="/backend/img/additional/3.jpg"
												alt="one"
												className="rounded"
												width={100}
											/>
										</td>
										<td className="vertical_align_middle">
											Bath Wellness
										</td>
										<td className="vertical_align_middle">
											PKR 60
										</td>
										<td className="vertical_align_middle">
											<FormCheck
												type="switch"
												className="switch_toggle"
											/>
										</td>
									</tr>
								</tbody>
							</table>

							{/* Coupon Code */}
							<div className="main_title_3 mt-3 mb-3">
								<span>
									<em></em>
								</span>
								<h2>Coupon Code</h2>
								<p className="mb-0">
									The discount will show on invoice page.
								</p>
							</div>

							{/* Coupon Code */}
							<div className="row">
								<div className="col-sm-12">
									<div className="form-group">
										<input
											type="text"
											id="Coupon Code"
											name="Coupon Code"
											className="form-control"
											placeholder="Coupon Code"
										/>
									</div>
								</div>
							</div>

							<Button className="btn_2 apply_coupon">
								Apply Coupon
							</Button>

							{/* Danger Alert */}
							<Alert
								variant="danger"
								className="border-0 py-4 px-4 my-3">
								<h5 className="font_weight_bold">
									Please read the terms before proceed
								</h5>
								<p className="mb-0 text_justify">
									When booking more than 9 rooms, different
									policies and additional supplements may
									apply. All children are welcome. Free! One
									child under 4 years stays free of charge
									when using existing beds. The maximum number
									of extra beds/children's cots permitted in a
									room is 1. Cancellation and prepayment
									policies vary according to room type. Please
									enter the dates of your stay and check the
									conditions of your required room.
								</p>
							</Alert>

							<p className="mb-3">
								By clicking to complete this booking I
								acknowledge that I have read and accept the
								Rules & Restrictions.
							</p>

							<div className="d-grid">
								<Button className="btn_2 rounded mx-auto" onClick={onNavigateInvoicePage}>
									Confirm This Booking
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CarBookingMainContent;
