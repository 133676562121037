import React from "react";
import * as Hooks from "../../../Hooks";
import { Badge, Button } from "react-bootstrap";
import * as Components from "../../../Components";
import CONSTANTS from "../../../Data";
import * as ReuseableComponents from "../../../ReuseableComponents";

const TourDetailsPackageType = () => {
	// States...
	const [facilities_card, setFacilitiesCard] = Hooks.useState(false);
	const [open_modal, setOpenModal] = Hooks.useState(false);

	return (
		<>
			{/* Table */}
			<table className="table">
				<thead>
					<tr>
						<th className="text-left">From-To Trip Duration</th>
						<th className="text-left">Days</th>
						<th className="text-left">Status</th>
						<th className="text-left">Guests</th>
						<th className="text-left">Price</th>
						<th className="text-left">Actions</th>
					</tr>
				</thead>
				<tbody>
					<tr className="available_rooms_row">
						<td>
							<ReuseableComponents.ReactIcons.CALENDAR_FILL className="calendar_icon" />
							August 01, 2023 <b>To</b> August 14, 2023
						</td>
						<td>
							<ReuseableComponents.ReactIcons.SUN_FILL className="sun_icon" />
							14
						</td>
						<td>
							<Badge className="tour_package_table_success_badge px-4 py-2">
								Available
							</Badge>
						</td>
						<td>
							<ReuseableComponents.ReactIcons.USERS className="users_icon" />
							05
						</td>
						<td>PKR 10,000</td>
						<td>
							<Button className="btn_2 btn-sm">Book</Button>
						</td>
					</tr>

					<tr className="available_rooms_row">
						<td>
							<ReuseableComponents.ReactIcons.CALENDAR_FILL className="calendar_icon" />
							July 22, 2023 <b>To</b> July 31, 2023
						</td>
						<td>
							<ReuseableComponents.ReactIcons.SUN_FILL className="sun_icon" />
							10
						</td>
						<td>
							<Badge className="tour_package_table_warning_badge px-4 py-2">
								Expired
							</Badge>
						</td>
						<td>
							<ReuseableComponents.ReactIcons.USERS className="users_icon" />
							02
						</td>
						<td>PKR 1,000</td>
						<td>
							<Button
								className="btn_2 btn-sm"
								onClick={() => setOpenModal(true)}>
								Book
							</Button>
						</td>
					</tr>
				</tbody>
			</table>

			{/* Buttons */}
			<div className="d-flex mt-3 justify-content-center">
				<Button
					className="btn_1 rounded"
					onClick={() => setFacilitiesCard(!facilities_card)}>
					Facilities
				</Button>
			</div>

			{/* Write Review Card */}
			{facilities_card === true && (
				<div className="container-fluid box_detail mt-3">
					<div className="row">
						<div className="col-sm-12 col-md-6">
							<h3>
								<ReuseableComponents.ReactIcons.CHECK className="check_icon" />{" "}
								Inclusions
							</h3>
						</div>
						<div className="col-sm-12 col-md-6 mt-3 mt-md-0">
							<h3>
								<ReuseableComponents.ReactIcons.OUTLINE_CLOSE className="cross_icon" />{" "}
								Exclusions
							</h3>
						</div>
					</div>

					<hr className="my-3" />

					<div className="row">
						<div className="col-sm-12 col-md-6">
							{CONSTANTS.TOUR_FACILITIES.inclusions.map(
								(item, index) => (
									<div
										className="col-12"
										key={index}>
										<p className="m-0 p-0 mb-2">
											<ReuseableComponents.ReactIcons.CHECK className="check_icon" />
											{item.label}
										</p>
									</div>
								),
							)}
						</div>
						<div className="col-sm-12 col-md-6 mt-3 mt-md-0">
							{CONSTANTS.TOUR_FACILITIES.exclusions.map(
								(item, index) => (
									<div
										className="col-12"
										key={index}>
										<p className="m-0 p-0 mb-2">
											<ReuseableComponents.ReactIcons.OUTLINE_CLOSE className="cross_icon" />
											{item.label}
										</p>
									</div>
								),
							)}
						</div>
					</div>
				</div>
			)}

			{/* Open Modal */}
			{open_modal && (
				<Components.TourBookingFormModal
					modalOpen={() => setOpenModal(true)}
					modalClose={() => setOpenModal(false)}
				/>
			)}
		</>
	);
};

export default TourDetailsPackageType;
