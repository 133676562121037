import React from "react";
import OwlCarousel from "react-owl-carousel";
import * as ReuseableComponents from "../../../ReuseableComponents";
import CONSTANTS from "../../../Data";

const CarDetailMainContent = () => {

	// Owl Carousel Options...
	const Options = {
		center: true,
		loop: true,
		nav: false,
		dots: false,
		autoplay: true,
		autoplayTimeout: 3000,
		autoplayHoverPause: true,
		responsiveClass: true,
		responsive: {
			0: {
				items: 1,
			},
			600: {
				items: 3,
			},
			1000: {
				items: 3,
			},
		},
	};

	return (
		<>
			<div className="bg_color_1">
				{/* Car Detail */}
				<ReuseableComponents.CarDetailContent />

				{/* Related Rentals */}
				<div className="main_title_2">
					<span>
						<em></em>
					</span>
					<h2>Related Rentals</h2>
				</div>
				<OwlCarousel
					className="owl-theme"
					id="reccomended"
					{...Options}>
					<ReuseableComponents.RelatedRentals
						data={CONSTANTS.FEATURED_CARS.featured_cars}
					/>
				</OwlCarousel>
			</div>
		</>
	);
};

export default CarDetailMainContent;
