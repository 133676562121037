import { useState } from "react";
import CustomeTextField from "./CustomeTextField";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const General = ({roomDescription,setRoomDescription}) => {
    console.log('7  :  ',roomDescription);

    return (
        <>

            <div className="mt-3">
                <CKEditor
                    editor={ClassicEditor}
                    data={roomDescription}
                    config={{ placeholder: "Room description" }}
                    onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        // editor.isInitializing = true;
                        // editor.setData(generalData.description);
                        // console.log('Editor is ready to use!', generalData.description);
                    }}
                    onBlur={(event, editor) => {
                        // console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                        // console.log('Focus.', editor);
                    }}
                    onChange={(event, editor) => {
                        // if (!editor.isInitializing) {
                            const data = editor.getData();
                            setRoomDescription(data);
                        // }
                        // console.log({ event, editor, data });
                    }}
                />
            </div>

        </>
    );
}

export default General;