import React from "react";
import * as Components from "../../../../Components";

const CarBooking = () => {
	// Site Title...
	document.title = "Car Booking || Travel Craze";

	return (
		<>
			{/* Top Banner */}
			<Components.TopBanner text="Car Booking" />

			{/* Car Booking Main Content */}
			<Components.CarBookingMainContent />
		</>
	);
};

export default CarBooking;
