import React from "react";
import * as Components from "../../../Components";

const Hotels = () => {
	// Site Title...
	document.title = "Hotels || Travel Craze";

	return (
		<>
			{/* Top Banner */}
			<Components.TopBanner text="Hotels" />

			{/* Hotel Main Content */}
			<Components.HotelMainContent />
		</>
	);
};

export default Hotels;
