import React from "react";
import $ from "jquery";
import * as Components from "../../../Components";
import * as Hooks from "../../../Hooks";
import { Button } from "react-bootstrap";

const CarDetailContent = () => {
	// States...
	const [pick_up_select_open, setPickUpSelectOpen] = Hooks.useState(false);
	const [pick_up_select, setPickUpSelect] = Hooks.useState(
		"Select Pick Up Location",
	);
	const [drop_off_select_open, setDropOffSelectOpen] = Hooks.useState(false);
	const [drop_off_select, setDropOffSelect] = Hooks.useState(
		"Select Drop Off  Location",
	);

	// Navigate...
	const navigate = Hooks.useNavigate();

	// Open Pick Up Select...
	const openPickUpSelect = () => {
		setPickUpSelectOpen(!pick_up_select_open);

		if (!pick_up_select_open) {
			document
				.querySelector(".add_bottom_15.pick_up")
				.classList.add("open");
		} else {
			document
				.querySelector(".add_bottom_15.pick_up")
				.classList.remove("open");
		}
	};

	// Open Drop Off Select...
	const openDropOffSelect = () => {
		setDropOffSelectOpen(!drop_off_select_open);

		if (!drop_off_select_open) {
			document
				.querySelector(".add_bottom_15.drop_off")
				.classList.add("open");
		} else {
			document
				.querySelector(".add_bottom_15.drop_off")
				.classList.remove("open");
		}
	};

	// Use Effect....
	Hooks.useEffect(() => {
		$('input[name="pick_up_date"]').daterangepicker(
			{
				autoUpdateInput: true,
				parentEl: ".scroll-fix",
				singleDatePicker: true,
				autoApply: true,
				minDate: new Date(),
				showCustomRangeLabel: false,
				locale: {
					format: "MM-DD-YYYY",
				},
			},
			function (start, end, label) {
				console.log(
					"New date selected: " +
						start.format("YYYY-MM-DD") +
						" to " +
						end.format("DD-MM-YYYY") +
						" (predefined range: " +
						label +
						")",
				);
			},
		);
	}, []);

	// On Navigate To Booking...
	const onNavigateToBooking = () => {
		navigate("/cars/booking");
	};

	return (
		<>
			<div className="container padding_30_30">
				<div className="row">
					{/* Main Content */}
					<div className="col-lg-8">
						{/* Description Content */}
						<section>
							<Components.CarDescriptionContent />
						</section>
					</div>

					{/* ASide */}
					<aside
						className="col-lg-4"
						id="sidebar">
						<div className="box_detail mb-0">
							{/* Booking options */}
							<div id="total_cart">Booking options</div>

							<div className="row">
								<div className="col-12">
									<div className="form-group">
										<label>Pick Up</label>

										<div className="custom-select-form">
											<div
												className="nice-select wide add_bottom_15 pick_up"
												tabIndex={0}
												onClick={() =>
													openPickUpSelect()
												}>
												<span className="current">
													{pick_up_select}
												</span>
												<ul className="list">
													<li
														className="option"
														onClick={() =>
															setPickUpSelect(
																"Select Pick Up Location",
															)
														}>
														Selct Pick Up Location
													</li>

													<li
														className="option"
														onClick={() =>
															setPickUpSelect(
																"United States Arab Emirates",
															)
														}>
														United States Arab
														Emirates
													</li>

													<li
														className="option"
														onClick={() =>
															setPickUpSelect(
																"Pakistan",
															)
														}>
														Pakistan
													</li>

													<li
														className="option"
														onClick={() =>
															setPickUpSelect(
																"India",
															)
														}>
														India
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>

							<hr className="my-3" />

							<div className="row">
								<div className="col-12">
									<div className="form-group">
										<label>Drop Off</label>

										<div className="custom-select-form">
											<div
												className="nice-select wide add_bottom_15 drop_off"
												tabIndex={0}
												onClick={() =>
													openDropOffSelect()
												}>
												<span className="current">
													{drop_off_select}
												</span>
												<ul className="list">
													<li
														className="option"
														onClick={() =>
															setDropOffSelect(
																"Select Drop Off Location",
															)
														}>
														Selct Drop Off Location
													</li>

													<li
														className="option"
														onClick={() =>
															setDropOffSelect(
																"United States Arab Emirates",
															)
														}>
														United States Arab
														Emirates
													</li>

													<li
														className="option"
														onClick={() =>
															setDropOffSelect(
																"Pakistan",
															)
														}>
														Pakistan
													</li>

													<li
														className="option"
														onClick={() =>
															setDropOffSelect(
																"India",
															)
														}>
														India
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>

							<hr className="my-3" />

							<div className="row">
								<div className="col-12">
									<div className="form-group">
										<label>Pick Up Time</label>
									</div>
								</div>
							</div>

							<hr className="my-3" />

							<div className="row">
								<div className="col-12">
									<div className="form-group">
										<label>Pick Up Date</label>
										<input
											className="form-control"
											type="text"
											name="pick_up_date"
											placeholder="Choose Pick Up Time"
										/>
										{/* <i className="icon_calendar" /> */}
									</div>
								</div>
							</div>

							<hr className="my-3" />

							{/* Charges */}
							<div id="total_cart">Charges</div>
							<ul className="cart_details mb-0">
								<li>
									Tax / VAT<span>PKR 00</span>
								</li>
								<li>
									Deposit Now <span>PKR 00</span>
								</li>
							</ul>

							<h6 className="mt-3">
								Total Amount{" "}
								<span className="float-end">PKR 00</span>
							</h6>

							<hr className="my-3" />

							<div className="d-grid">
								<Button
									className="btn_2 mx-auto"
									onClick={onNavigateToBooking}>
									Book Now
								</Button>
							</div>
						</div>
					</aside>
				</div>
			</div>
		</>
	);
};

export default CarDetailContent;
