import React from "react";
import { Button } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import * as Hooks from "../../../../Hooks";
import * as ReuseableComponents from "../../../../ReuseableComponents";

const CustomerLogin = () => {
	// Site Title...
	document.title = "Login || Travel Craze";

	// Use Effect...
	Hooks.useEffect(() => {
		document.body.id = "login_bg";

		return () => {
			document.body.id = "";
		};
	}, []);
	return (
		<>
			<div id="login">
				<aside>
					<figure>
						<a href="index.html">
							<img
								src="/backend/logo.png"
								width="250"
								alt="Logo Image"
								className="logo_sticky"
							/>
						</a>
					</figure>
					<form autoComplete="off">
						{/* Contact No */}
						<div className="form-group">
							<label>Contact No</label>
							<PhoneInput
								country={"pk"}
								enableSearch={true}
								className="contact_no_input"
								placeholder="Enter your Contact No"
								name="contact_no"
							/>
						</div>

						{/* Password */}
						<div className="form-group">
							<label>Password</label>
							<input
								type="password"
								className="form-control"
								placeholder="Enter Your Password"
								name="password"
							/>
						</div>

						{/* Button */}
						<Button className="btn_1 rounded full-width add_top_30">
							Login To Travel Craze!
						</Button>

						{/* Bottom Text */}
						<div className="text-center add_top_10">
							Don't have an acccount?
							<strong>
								<Hooks.Link
									to="/register"
									className="ms-2 footer_website_link">
									Register
								</Hooks.Link>
							</strong>
						</div>
					</form>

					<hr className="my-3" />

					{/* Copy Right */}
					<ReuseableComponents.FooterBottom />
				</aside>
			</div>
		</>
	);
};

export default CustomerLogin;
